import { ApolloError } from '@apollo/client';
import hexToRgba from 'hex-to-rgba';
import { Component, memo, RefCallback, useCallback, useContext } from 'react';
import {
  AccessibilityInfo,
  findNodeHandle,
  Keyboard,
  Platform,
  StyleProp,
  ViewStyle,
} from 'react-native';

import { Markdown } from '../components/Markdown';
import { ScrollViewContext } from '../components/ScrollView';
import { Text } from '../components/Text';
import { UnorderedList } from '../components/UnorderedList';
import { View } from '../components/View';
import { useI18n } from '../lib/i18n';
import { useTheme } from '../styles';

const ERROR_LOOKUP: { [key: string]: string | undefined } = {
  EMAIL_IN_USE: 'A user already exists for the given email.',
  DUPLICATE: 'A user already exists for the given email.',
  USER_EXISTS: 'A user already exists for the given email.',
  INVALID_EMAIL: 'The email provided is not valid.',
  NOT_FOUND: 'This record could not be found.',
  UNAUTHORIZED: 'You are not authorized to perform this request.',
};

const TEXT_COLOR = '#932a2a';

// We use memo() here because inline ref callbacks are called on every render vs class
// style react where they are called only on mount/unmount.
// We don't want to useCallback for our ref callback because then we wouldn't run if errorString or
// error are changed
// https://reactjs.org/docs/refs-and-the-dom.html#caveats-with-callback-refs
export const ErrorPresenter = memo<{
  errorString?: string;
  error?: ApolloError;
  formErrors?: Record<string, string | undefined | { label: string; message: string }>;
  style?: StyleProp<ViewStyle>;
}>(({ error, errorString, formErrors, style }) => {
  const { theme } = useTheme();
  const { scrollToElement } = useContext(ScrollViewContext);
  const { $t } = useI18n();
  const ref: RefCallback<Component> = useCallback(
    (r) => {
      if (r) {
        if (Platform.OS !== 'web') {
          Keyboard.dismiss();
        }
        scrollToElement(r);
        setTimeout(() => {
          const handle = findNodeHandle(r)!;
          if (handle) {
            // If the element requires scrolling to be visible, we must wait for scrolling
            // before setAccessibilityFocus. Otherwise focus isn't properly performed.
            AccessibilityInfo.setAccessibilityFocus(handle);
          }
        }, 250);
      }
    },
    [scrollToElement],
  );

  return errorString || error || Object.keys(formErrors ?? {}).length ? (
    <View
      style={[
        {
          padding: 16,
          paddingHorizontal: 36,
          borderRadius: 8,
          backgroundColor: hexToRgba(theme.color.danger, 0.1),
        },
        style,
      ]}
      accessible
      ref={ref}
      spacing={8}
      testID="ErrorPresenter"
    >
      {errorString ? (
        <View spacing={8}>
          <Text text="Error" weight="semibold" color={TEXT_COLOR} />
          <Text text={errorString} color={TEXT_COLOR} />
        </View>
      ) : error ? (
        error.graphQLErrors.map((e, i) => {
          return (
            <View key={i}>
              <Text
                text={
                  ERROR_LOOKUP[e.message] ||
                  ERROR_LOOKUP[e.extensions?.message as string] ||
                  'An unknown error has occurred.'
                }
                color={TEXT_COLOR}
              />
            </View>
          );
        })
      ) : null}
      {formErrors && Object.keys(formErrors).length ? (
        <View spacing={8}>
          <Markdown style={{ text: { color: TEXT_COLOR } }}>
            {$t(
              {
                id: 'ErrorPresenter_fieldErrorsHeading',
                defaultMessage:
                  '**{numErrors, plural, one{1 error} other{# errors}}** in the following fields:',
              },
              {
                numErrors: Object.keys(formErrors).length,
              },
            )}
          </Markdown>
          <View style={{ marginLeft: 8 }}>
            <UnorderedList
              size="small"
              color={TEXT_COLOR}
              textColor={TEXT_COLOR}
              weight="normal"
              items={Object.entries(formErrors).map(([label, value]) => {
                if (typeof value === 'object') {
                  return `${value.label}: ${value.message}`;
                }
                label = label.replace(' *', '').replace('*', '');
                return value ? `${label}: ${value}` : label;
              })}
            />
          </View>
        </View>
      ) : null}
    </View>
  ) : null;
});
