import { useMutation, useQuery } from '@apollo/client';
import { useActionSheet } from '@expo/react-native-action-sheet';
import { useFocusEffect, useNavigation } from '@react-navigation/native';
import * as ImagePicker from 'expo-image-picker';
import * as ScreenOrientation from 'expo-screen-orientation';
import { useCallback } from 'react';
import { Image, Platform, TouchableOpacity } from 'react-native';

import { Avatar } from '@oui/app-core/src/components/Avatar/Avatar';
import { Icon } from '@oui/app-core/src/components/Icon';
import { Heading } from '@oui/app-core/src/components/Text';
import { View } from '@oui/app-core/src/components/View';
import { useI18n } from '@oui/app-core/src/lib/i18n';
import { resumableUploadManager, SessionUri } from '@oui/app-core/src/lib/resumableUploadManager';
import Sentry from '@oui/app-core/src/sentry';
import { useTheme } from '@oui/app-core/src/styles';
import { graphql } from '@oui/lib/src/graphql/tada';

import { TabScreenProps } from '../../types/navigation';

export type ProfileQueryName = 'ProfileQuery';
export const ProfileQuery = graphql(`
  query Profile {
    user {
      givenName
      familyName
      ID
    }
    avatar: asset(context: "user", key: "avatar")
  }
`);

export const ProfileMutation = graphql(`
  mutation Profile {
    uploadResumableAsset(
      input: { context: "user", key: "avatar", fileName: "avatar.jpeg", uploadType: IMAGE }
    ) {
      resumableUploadAssetURI
    }
  }
`);

export const Profile = () => {
  const { theme } = useTheme();
  const { $t } = useI18n();
  const { data, refetch } = useQuery(ProfileQuery);
  const [uploadAvatar] = useMutation(ProfileMutation);
  const { showActionSheetWithOptions } = useActionSheet();
  const { navigate } = useNavigation<TabScreenProps<'UserProfile'>['navigation']>();
  const user = data && data.user;

  useFocusEffect(
    useCallback(() => {
      if (Platform.OS !== 'web') {
        ScreenOrientation.lockAsync(ScreenOrientation.OrientationLock.PORTRAIT_UP);
        return () => ScreenOrientation.unlockAsync();
      }

      return;
    }, []),
  );

  return (
    <View
      style={{
        backgroundColor: theme.color.dark,
        alignItems: 'center',
        marginTop: 45,
      }}
    >
      <Icon
        size={20}
        testID="Profile_settingsButton"
        accessibilityLabel={$t({
          id: 'Profile_settingsButton',
          defaultMessage: 'Settings',
        })}
        name="settings"
        color={theme.color.gray400}
        onPress={() => {
          navigate('Account', {
            isEditing: 'false',
          });
        }}
        style={{ position: 'absolute', top: -44, right: 18 }}
      />

      <View
        style={{
          marginTop: -45,
          paddingBottom: 15,
          alignItems: 'center',
          gap: 10,
        }}
      >
        <TouchableOpacity
          testID="Profile_profilePhotoButton"
          accessibilityLabel={$t({
            id: 'Profile_profilePhotoButton',
            defaultMessage: 'Profile photo',
          })}
          style={{
            width: 100,
            height: 100,
          }}
          accessibilityRole="button"
          onPress={() => {
            showActionSheetWithOptions(
              {
                options: [
                  $t({ id: 'Profile_choosePhotoCancelOption', defaultMessage: 'Cancel' }),
                  $t({
                    id: 'Profile_choosePhotoGalleryOption',
                    defaultMessage: 'Choose from gallery',
                  }),
                  $t({
                    id: 'Profile_choosePhotoCameraOption',
                    defaultMessage: 'Take a photo',
                  }),
                ],
                cancelButtonIndex: 0,
              },
              async (buttonIndex) => {
                if (typeof buttonIndex !== 'number') return;
                if (buttonIndex > 0) {
                  const useGallery = buttonIndex === 1;
                  const [{ status }] = await Promise.all(
                    useGallery
                      ? [ImagePicker.requestMediaLibraryPermissionsAsync()]
                      : [
                          ImagePicker.requestCameraPermissionsAsync(),
                          ImagePicker.requestMediaLibraryPermissionsAsync(),
                        ],
                  );

                  if (status === 'granted') {
                    const options = {
                      mediaTypes: ImagePicker.MediaTypeOptions.Images,
                      allowsEditing: false,
                      quality: 1,
                    };
                    const promise = global.e2e
                      ? Promise.resolve<ImagePicker.ImagePickerResult>({
                          canceled: false,
                          assets: [
                            {
                              uri:
                                Platform.OS === 'ios'
                                  ? // For some reason iOS can't fetch picsum.photos, but android can
                                    // Web can't fetch placedog.net b/c of a CORS issue
                                    'https://placedog.net/500/500'
                                  : 'https://picsum.photos/500',
                              width: 500,
                              height: 500,
                            },
                          ],
                        })
                      : useGallery
                        ? ImagePicker.launchImageLibraryAsync(options)
                        : ImagePicker.launchCameraAsync(options);

                    promise.then((result) => {
                      if (!result.canceled) {
                        uploadAvatar({
                          refetchQueries: ['ProfileQuery'],
                        })
                          .then(async (avatarResult) => {
                            const id = await resumableUploadManager.uploadFile(
                              result.assets![0].uri,
                              avatarResult.data?.uploadResumableAsset
                                .resumableUploadAssetURI as SessionUri,
                            );
                            const remove = resumableUploadManager.addListener(id, ({ percent }) => {
                              if (percent === 100) {
                                refetch();
                                remove();
                              }
                            });
                          })
                          .catch(Sentry.captureException);
                      }
                    });
                  }
                }
              },
            );
          }}
        >
          <Icon
            name="camera"
            color={theme.color.primary100}
            style={{
              bottom: 12,
              left: 40,
              position: 'absolute',
              zIndex: 1,
            }}
          />

          {data?.avatar ? (
            <Image
              style={{
                width: 100,
                height: 100,
                borderRadius: 50,
                backgroundColor: theme.color.accentTwo100,
              }}
              source={{ uri: data?.avatar }}
              testID="Profile_avatar"
            />
          ) : (
            <Avatar name={user ? `${user.givenName} ${user.familyName}` : ''} size={100} round />
          )}
        </TouchableOpacity>

        <Heading
          text={user ? `${user.givenName} ${user.familyName}` : ''}
          level={1}
          variant="light"
        />
      </View>
    </View>
  );
};
