import * as LocalAuthentication from 'expo-local-authentication';
import { useEffect, useState } from 'react';
import { Platform } from 'react-native';

import { nativeVersionAtLeast } from '../constants';
import { usePersistedState } from '../hooks/usePersistedState';

enum CustomAuthenticationType {
  PIN = 0,
  BIOMETRIC = 101,
}

export const AuthenticationTypes = {
  ...CustomAuthenticationType,
  ...LocalAuthentication.AuthenticationType,
};

const DEFAULT_AUTH_TYPES: AuthenticationType[] =
  Platform.OS === 'web' ? [] : [AuthenticationTypes.PIN];

export type AuthenticationType = LocalAuthentication.AuthenticationType | CustomAuthenticationType;

export function useReauthenticationState(): {
  addEnrollment: (type: AuthenticationType) => void;
  removeEnrollment: (type: AuthenticationType) => void;
  isLoading: boolean;
  enrolledTypes: AuthenticationType[];
  supportedTypes: AuthenticationType[];
} {
  const [enrolledTypes, setEnrolledTypes, isLoading] = usePersistedState(
    'enrolledReauthenticationTypes',
    [],
  );
  const [supportedTypes, setSupportedTypes] = useState<AuthenticationType[]>(DEFAULT_AUTH_TYPES);

  function addEnrollment(type: AuthenticationType) {
    setEnrolledTypes([...enrolledTypes, type]);
  }

  function removeEnrollment(type: AuthenticationType) {
    setEnrolledTypes(enrolledTypes.filter((i) => i !== type));
  }

  useEffect(() => {
    // https://github.com/expo/expo/issues/7838#issuecomment-613702149
    if (Platform.OS !== 'android' || Platform.Version >= 23) {
      LocalAuthentication.isEnrolledAsync().then((isEnrolled) => {
        if (isEnrolled) {
          LocalAuthentication.supportedAuthenticationTypesAsync().then((types) => {
            let newTypes: AuthenticationType[] = types;
            if (Platform.OS === 'android' && types.length && !nativeVersionAtLeast('0.3.0')) {
              newTypes = [AuthenticationTypes.BIOMETRIC];
            }
            setSupportedTypes([...DEFAULT_AUTH_TYPES, ...newTypes]);
          });
        }
      });
    }
  }, []);

  return {
    isLoading,
    enrolledTypes: enrolledTypes.filter(
      (t) => supportedTypes.includes(t) || t === AuthenticationTypes.BIOMETRIC,
    ),
    supportedTypes,
    addEnrollment,
    removeEnrollment,
  };
}
