import { ComponentProps, forwardRef } from 'react';
import { useFormContext } from 'react-hook-form';
import { TextInput as RNTextInput } from 'react-native';
import { TextInputMask } from 'react-native-masked-text';

import { TextInput } from '../components/TextInput';
import { InputRenderer } from '../form';

const TextInputWrapper = forwardRef<
  RNTextInput,
  ComponentProps<typeof TextInput> & ComponentProps<typeof TextInputMask>
>(({ customTextInput, customTextInputProps, ...p }, ref) => <TextInput {...p} ref={ref} />);

export function PhoneInput({ value, onChangeValue, ...props }: ComponentProps<typeof TextInput>) {
  return (
    <TextInputMask
      type="custom"
      options={{
        mask: '(999) 999 - 9999',
      }}
      customTextInput={TextInputWrapper}
      customTextInputProps={{
        placeholder: '(555) 555 - 5555',
        keyboardType: 'phone-pad',
        autoCompleteType: 'tel',
        ...props,
      }}
      onChangeText={onChangeValue}
      value={value || ''}
    />
  );
}

export const PhoneInputRender: InputRenderer<ComponentProps<typeof PhoneInput>> = (
  renderProps,
  props,
) => {
  const {
    field: { onChange, onBlur, value, name },
    fieldState: { error },
  } = renderProps;
  const formContext = useFormContext();
  if (formContext && props.label) formContext.labels[name] = props.label as string;
  return (
    <PhoneInput
      testID={`FormInput_${name.replaceAll('.', '_')}`}
      error={error?.message}
      onChangeValue={(v) => {
        onChange(v);
        onBlur();
      }}
      value={value}
      {...props}
    />
  );
};
