import { ComponentProps, ReactElement } from 'react';
import { StyleProp, TouchableOpacity, ViewStyle } from 'react-native';

import { Text } from '../components/Text';
import { Color } from '../styles';

export function Link({
  disabled,
  onPress,
  style,
  testID,
  text,
  textAlign,
}: {
  disabled?: boolean;
  onPress?: () => void;
  style?: StyleProp<ViewStyle>;
  testID?: string;
  text: string | ReactElement;
  textAlign?: ComponentProps<typeof Text>['textAlign'];
}) {
  return (
    <TouchableOpacity
      disabled={disabled || !onPress}
      onPress={onPress}
      style={[disabled ? { opacity: 0.6 } : null, style]}
      testID={testID}
      accessibilityRole="link"
    >
      <Text
        color={Color.styleGuide.Gray3}
        text={text}
        textAlign={textAlign}
        size={14}
        style={{ lineHeight: 18, textDecorationLine: onPress ? 'underline' : undefined }}
      />
    </TouchableOpacity>
  );
}
