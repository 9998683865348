import { useMutation, useQuery } from '@apollo/client';

import { graphql, ResultOf } from '@oui/lib/src/graphql/tada';
import { ContentType, ProductVariant, ProductVersion } from '@oui/lib/src/types';

import { useCurrentPatient } from '../../../hooks/useCurrentUser';
import { useI18n } from '../../../lib/i18n';
import { Button } from '../../Button';
import { View } from '../../View';
import { SessionVideosQuery } from '../Videos/Videos';

const MarkAsCompleteMutation = graphql(`
  mutation MarkAsComplete($content: ContentType!, $value: Float!) {
    setOuiProgress(content: $content, value: $value) {
      completed
      completion
      content
      updatedAt
    }
  }
`);

export type MarkCompleteProps = {
  onComplete: (mutationData?: ResultOf<typeof MarkAsCompleteMutation> | null) => void;
  contentType: ContentType;
};

export function MarkComplete({ onComplete, contentType }: MarkCompleteProps) {
  const { user } = useCurrentPatient();
  const productVariant = user?.role.product.slug || ProductVariant.AVIVA_INPATIENT;
  const productVersion = user?.productVersion || ProductVersion.AVIVA_2_0;

  const { data } = useQuery(SessionVideosQuery, {
    variables: {
      contentType,
      productVariant,
      productVersion,
    },
    fetchPolicy: 'cache-only',
  });

  const { $t } = useI18n();
  const [markAsComplete, { data: mutationData }] = useMutation(MarkAsCompleteMutation);

  const request = async () => {
    const promises = [];
    for (let contentType of data?.sessionContentForApp?.contentTypes ?? []) {
      promises.push(
        markAsComplete({
          variables: { content: contentType as ContentType, value: 1 },
        }),
      );
    }
    await Promise.all(promises);
    onComplete(mutationData);
  };

  return (
    <View
      testID="Conversation_markSessionComplete"
      style={[
        {
          flex: 1,
          flexDirection: 'column',
          justifyContent: 'center',
        },
      ]}
    >
      <View
        row
        style={[
          {
            justifyContent: 'center',
          },
        ]}
      >
        <Button
          onPress={request}
          text={$t({
            id: 'Conversation_markSessionComplete',
            defaultMessage: 'Mark session complete',
          })}
          variant="contained"
        />
      </View>
    </View>
  );
}
