/* eslint-disable @typescript-eslint/no-explicit-any */
import RNMarkdown, { hasParents } from '@ronradtke/react-native-markdown-display';
import merge from 'lodash/merge';
import { ReactNode, useMemo } from 'react';
import { Image, Platform, StyleSheet, View } from 'react-native';

import { Text } from '../components/Text';
import { useTheme } from '../styles';

// Images https://ouitherapeutics.slack.com/archives/CNRUWC7RC/p1570120768018000
export function Markdown(props: {
  children: ReactNode;
  fontFamily?: string;
  style?: StyleSheet.NamedStyles<any>;
  textColor?: string;
}) {
  const { theme } = useTheme();
  const style: StyleSheet.NamedStyles<any> = useMemo(() => {
    const defaultStyle = {
      body: {
        fontSize: theme.typography.body.size,
        lineHeight: theme.typography.body.lineHeight,
        width: '100%',
        fontFamily: props.fontFamily || 'OpenSansRegular',
        color: props.textColor,
      },
      heading1: {
        paddingVertical: 10,
        fontSize: 32,
        fontFamily: 'OpenSansBold',
      },
      heading2: {
        paddingVertical: 10,
        fontSize: 24,
        fontFamily: 'OpenSansBold',
      },
      heading3: {
        paddingVertical: 10,
        fontSize: 18,
        fontFamily: 'OpenSansBold',
      },
      heading4: {
        paddingVertical: 10,
        fontSize: 16,
        fontFamily: 'OpenSansBold',
      },
      heading5: {
        paddingVertical: 10,
        fontSize: 13,
        fontFamily: 'OpenSansBold',
      },
      heading6: {
        paddingVertical: 10,
        fontSize: 11,
        fontFamily: 'OpenSansBold',
      },
      strong: {
        fontSize: 16,
        fontFamily: 'OpenSansBold',
        color: props.textColor,
      },
      bullet_list_icon: {
        marginTop: (theme.typography.body.lineHeight - 5) / 2,
        backgroundColor: 'black',
        borderRadius: 5,
        height: 5,
        marginRight: 10,
        width: 5,
      },
      list_item: { flexShrink: 1, flexDirection: 'row', justifyContent: 'flex-start' },
    };

    return merge({}, defaultStyle, props.style);
  }, [props.textColor, props.fontFamily, props.style, theme]);

  return (
    <RNMarkdown
      style={style}
      rules={{
        image: (node, children, parent, styles) => {
          let content = null;
          try {
            if (node.content && node.content.startsWith('{')) {
              content = JSON.parse(node.content);
            }
          } catch (e) {}
          return (
            <Image
              key={node.key}
              style={[
                styles.image,
                {
                  flex: 0,
                  flexGrow: 1,
                  resizeMode: 'contain',
                  minHeight: 50,
                  alignSelf: 'flex-start',
                },
                content,
              ]}
              source={{
                uri: node.attributes.src,
              }}
            />
          );
        },
        paragraph: (node, children, parent, styles) => {
          const anyParent = (parent as any)?.[0];
          const parentIsRoot = anyParent.type === 'body';
          const isFirstRootChild = parentIsRoot && node.key === anyParent.children[0]?.key;
          const isLastRootChild =
            parentIsRoot && node.key === anyParent.children[anyParent.children.length - 1]?.key;
          const isInListItem = hasParents(parent as any, 'list_item');
          const hasImageChild = !!node.children.find((child: any) => child.sourceType === 'image');
          return (
            <View
              key={node.key}
              style={[
                styles.paragraph,
                isFirstRootChild ? { marginTop: 0 } : 0,
                isLastRootChild ? { marginBottom: 0 } : 0,
                isInListItem ? { marginTop: 0, marginBottom: 5 } : null,
                hasImageChild ? { alignItems: 'center' } : null,
              ]}
            >
              {children}
            </View>
          );
        },

        list_item: (node, children, parent, styles, inheritedStyles = {}) => {
          if (hasParents(parent as any, 'bullet_list')) {
            return (
              <View key={node.key} style={styles.list_item}>
                <View style={styles.bullet_list_icon} />
                <View style={styles.bullet_list_content}>{children}</View>
              </View>
            );
          }

          if (hasParents(parent as any, 'ordered_list')) {
            const depth = (parent as any).filter(
              (p: typeof node) => p.type === 'ordered_list',
            ).length;

            const isAlphaList = depth % 2 === 0;

            const orderedListIndex = (parent as any)!.findIndex(
              (el: typeof node) => el.type === 'ordered_list',
            );

            const orderedList = (parent as any)[orderedListIndex];
            let listItemNumber;

            if (orderedList.attributes && orderedList.attributes.start) {
              listItemNumber = orderedList.attributes.start + node.index;
            } else {
              listItemNumber = node.index + 1;
            }

            return (
              <View key={node.key} style={[styles.list_item]}>
                <Text
                  style={[
                    styles.ordered_list_icon,
                    {
                      lineHeight: Platform.select({ default: undefined, android: 18 }),
                      alignSelf: 'flex-start',
                    },
                  ]}
                  text={
                    isAlphaList
                      ? `${String.fromCharCode('a'.charCodeAt(0) + node.index)}${node.markup} `
                      : `${listItemNumber}${node.markup} `
                  }
                />
                <View style={styles.ordered_list_content}>{children}</View>
              </View>
            );
          }

          return (
            <View key={node.key} style={styles.list_item}>
              {children}
            </View>
          );
        },
      }}
    >
      {props.children as string}
    </RNMarkdown>
  );
}
