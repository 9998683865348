import AsyncStorage from '@react-native-async-storage/async-storage';
import * as Sentry from '@sentry/core';
// @ts-expect-error
import computeMd5 from 'blueimp-md5';
import * as FileSystem from 'expo-file-system';
import { createContext } from 'react';
import { Platform } from 'react-native';
import { v4 as uuid } from 'uuid';

import { retry } from '@oui/lib/src/retry';
import { GQLUUID } from '@oui/lib/src/types/scalars';

import { addBreadcrumb } from '../lib/log';
import { GcsResumableUploader, SessionUri } from '../lib/resumableUpload';

export { SessionUri };

const CANCELED_UPLOAD_URI = '__CANCELED__UPLOAD__' as SessionUri;
const ASYNC_STORAGE_KEY = 'resumableUploadManagerData';

type PendingUpload<T = unknown> = {
  ID: GQLUUID;
  sourceUri: string;
  sessionUri: SessionUri;
  cacheKey?: string;
  metaData?: T;
};
export type Data = { pendingUploads: PendingUpload[] };
export type ProgressCallback = (payload: {
  progress: number;
  total: number;
  percent: number;
}) => void;
type RefreshUriCallback = (upload: PendingUpload) => Promise<string | null>;
export interface Uploader {
  initializeResumableUpload(blob: Blob, fileName: string): Promise<SessionUri>;
  checkResumableUpload: (
    sessionUri: SessionUri,
  ) => Promise<{ isComplete: boolean; lastByte: number }>;
  uploadResumableChunk(
    sessionUri: SessionUri,
    blob: Blob,
    start: number,
  ): Promise<{ isComplete: boolean; lastByte: number }>;
}
export interface Storage {
  getItem(key: string): Promise<string | null>;
  setItem(key: string, value: string): Promise<void>;
}

const DEFAULT_DATA = { pendingUploads: [] };
const MAX_UPLOADS_IN_FLIGHT = 4;

export class ResumableUploadManager {
  private cachedData: Data | null = null;
  private blobCache: Record<string, Blob | undefined> = {};
  private uploadsInFlight = new Set<GQLUUID>();
  private progressListeners: Record<GQLUUID, ProgressCallback[] | undefined> = {};
  private uploader: Uploader;
  private storage: Storage;
  private refreshUriCallbacks: RefreshUriCallback[] = [];
  private getBlob: (uri: string) => Promise<Blob>;

  constructor({
    uploader,
    storage,
    getBlob,
  }: {
    uploader: Uploader;
    storage?: Storage;
    getBlob?: (uri: string) => Promise<Blob>;
  }) {
    this.uploader = uploader;
    this.storage = storage ?? AsyncStorage;
    this.getBlob = getBlob ?? (async (uri: string) => (await fetch(uri)).blob());
  }

  registerUriRefreshCallback(cb: RefreshUriCallback) {
    this.refreshUriCallbacks.push(cb);
  }

  private async loadData(): Promise<Data> {
    if (this.cachedData) return this.cachedData;
    if (Platform.OS === 'web') {
      this.cachedData = DEFAULT_DATA;
    } else {
      const persistedData = await this.storage.getItem(ASYNC_STORAGE_KEY);
      if (persistedData) {
        const tmpData = JSON.parse(persistedData) as Data;
        this.cachedData = {
          // migrate from potentially old stored data which didn't used to have ID
          pendingUploads: tmpData.pendingUploads.map((d) => ({
            ...d,
            ID: d.ID ?? (uuid() as GQLUUID),
          })),
        };
      } else {
        this.cachedData = DEFAULT_DATA;
      }
    }
    return this.cachedData;
  }

  private async saveData(data: Data) {
    this.cachedData = data;
    if (Platform.OS !== 'web') {
      await this.storage.setItem(ASYNC_STORAGE_KEY, JSON.stringify(data));
    }
  }

  private async getSessionUri(ID: GQLUUID) {
    const data = await this.loadData();
    const uploadEntry = data.pendingUploads.find((pending) => pending.ID === ID);
    return uploadEntry?.sessionUri;
  }

  private async getBlobForUri(uri: string, allowRecovery: boolean = true): Promise<Blob> {
    const cachedBlob = this.blobCache[uri];
    if (cachedBlob) return cachedBlob;
    try {
      const blob = await this.getBlob(uri);
      this.blobCache[uri] = blob;
      return blob;
    } catch (e) {
      try {
        const data = await this.loadData();
        const pendingUpload = data.pendingUploads.find((u) => u.sourceUri === uri);
        Sentry.captureMessage('ResumableUploadManager.getBlobForUri refreshing uri', {
          extra: { data, uri, pendingUpload },
        });
        if (allowRecovery && pendingUpload) {
          for (let refreshUriCallback of this.refreshUriCallbacks) {
            const newUri = await refreshUriCallback(pendingUpload);
            if (newUri) {
              const newData = {
                pendingUploads: data.pendingUploads.map((pending) =>
                  pending.sourceUri === uri ? { ...pending, uri: newUri } : pending,
                ),
              };
              await this.saveData(newData);
              return this.getBlobForUri(newUri, false);
            }
          }
        }
      } catch (e) {
        Sentry.captureException('ResumableUploadManager.getBlobForUri refreshing failed', {
          extra: { uri, error: e, cachedData: this.cachedData },
        });
      }

      Sentry.captureException('ResumableUploadManager.getBlobForUri failed', {
        extra: { uri, error: e, cachedData: this.cachedData },
      });
      (e as any).status = 400;
      throw e;
    }
  }

  private async completeUpload(ID: GQLUUID) {
    const data = await this.loadData();
    const matchingUpload = data.pendingUploads.find((u) => u.ID === ID);
    addBreadcrumb({
      category: 'resumableUploadManager',
      message:
        matchingUpload?.sessionUri === CANCELED_UPLOAD_URI ? 'canceledUpload' : 'completeUpload',
      data: {
        uploadsInFlight: this.uploadsInFlight.size,
        MAX_UPLOADS_IN_FLIGHT,
        ID,
        pendingUploadsLength: data.pendingUploads.length,
      },
    });
    this.uploadsInFlight.delete(ID);
    const newData = {
      pendingUploads: data.pendingUploads.filter((pending) => pending.ID !== ID),
    };
    await this.saveData(newData);
    if (matchingUpload?.sourceUri.endsWith('.toupload')) {
      await FileSystem.deleteAsync(matchingUpload?.sourceUri, { idempotent: true });
    }
    await this.startPendingUploads();
  }

  getPendingUploadByCacheKey<T>(cacheKey: string) {
    return this.cachedData?.pendingUploads.find((up) => up.cacheKey === cacheKey) as
      | PendingUpload<T>
      | undefined;
  }

  private async startUpload(ID: GQLUUID, startByte?: number) {
    addBreadcrumb({
      category: 'resumableUploadManager',
      message: 'startUpload',
      data: {
        uploadsInFlight: this.uploadsInFlight.size,
        MAX_UPLOADS_IN_FLIGHT,
        ID,
        startByte,
        isInFlightUri: this.uploadsInFlight.has(ID),
      },
    });
    if (this.uploadsInFlight.size >= MAX_UPLOADS_IN_FLIGHT && !this.uploadsInFlight.has(ID)) return;
    this.uploadsInFlight.add(ID);
    const sessionUri = await this.getSessionUri(ID);
    if (!sessionUri) {
      Sentry.captureMessage('resumableUploadManager startUpload missing sessionUri', {
        extra: {
          uploadsInFlight: this.uploadsInFlight.size,
          MAX_UPLOADS_IN_FLIGHT,
          ID,
          startByte,
          isInFlightUri: this.uploadsInFlight.has(ID),
        },
      });
      return;
    }

    if (sessionUri === CANCELED_UPLOAD_URI) {
      return this.completeUpload(ID);
    }

    if (typeof startByte !== 'number') {
      const { isComplete, lastByte } = await this.uploader.checkResumableUpload(sessionUri);
      if (isComplete) return await this.completeUpload(ID);
      startByte = lastByte + 1;
    }

    const data = await this.loadData();
    const uri = data.pendingUploads.find((u) => u.ID === ID)?.sourceUri;

    if (!uri) {
      Sentry.captureMessage('resumableUploadManager startUpload missing sourceUri', {
        extra: {
          uploadsInFlight: this.uploadsInFlight.size,
          MAX_UPLOADS_IN_FLIGHT,
          ID,
          startByte,
          isInFlightUri: this.uploadsInFlight.has(ID),
        },
      });
      return;
    }

    const blob = await this.getBlobForUri(uri);
    const { isComplete, lastByte } = await this.uploader.uploadResumableChunk(
      sessionUri,
      blob,
      startByte,
    );

    const progressPayload = isComplete
      ? { progress: blob.size, total: blob.size, percent: 100 }
      : {
          progress: lastByte,
          total: blob.size,
          percent: Math.round((lastByte / blob.size) * 100),
        };

    addBreadcrumb({
      category: 'resumableUploadManager',
      message: 'startUpload result',
      data: {
        uploadsInFlight: this.uploadsInFlight.size,
        MAX_UPLOADS_IN_FLIGHT,
        uri,
        isComplete,
        startByte,
        lastByte,
        progressPayload,
      },
    });

    this.progressListeners[ID]?.forEach((cb) => cb(progressPayload));
    if (isComplete) {
      await this.completeUpload(ID);
    } else {
      this.startUploadWithRetries(ID, lastByte + 1);
    }
  }

  private async startUploadWithRetries(ID: GQLUUID, startByte?: number) {
    try {
      await retry(
        async () => {
          try {
            return await this.startUpload(ID, startByte);
          } catch (e: any) {
            if ([400, 401, 404].includes(e.status)) {
              Sentry.captureException(e, { extra: { ID, startByte } });
              const data = await this.loadData();
              // TODO add callback for failed uploads
              await this.saveData({
                pendingUploads: data.pendingUploads.filter((pending) => pending.ID !== ID),
              });
            } else {
              throw e;
            }
          }
        },
        {
          timeout: 60000,
          logger: (message) => {
            Sentry.addBreadcrumb({
              message: 'startUploadWithRetries retry result',
              data: { ID, startByte, message },
            });
          },
          maxBackOff: process.env.NODE_ENV === 'test' ? 1 : undefined,
        },
      );
    } catch (e: any) {
      Sentry.captureException(e, { extra: { ID, startByte } });
      Sentry.captureException('ResumableUploadManager error', {
        extra: { ID, startByte, err: e.toString() },
      });
    }
  }

  async startPendingUploads() {
    const data = await this.loadData();
    for (let i = 0; i < data.pendingUploads.length; i++) {
      if (this.uploadsInFlight.size >= MAX_UPLOADS_IN_FLIGHT) break;
      const ID = data.pendingUploads[i].ID;
      if (this.uploadsInFlight.has(ID)) continue;
      this.startUploadWithRetries(ID);
    }
  }

  addListener(ID: GQLUUID, callback: ProgressCallback) {
    if (!this.progressListeners[ID]) {
      this.progressListeners[ID] = [];
    }
    this.progressListeners[ID]?.push(callback);
    return () => {
      const remainingListeners = this.progressListeners[ID]?.filter((l) => l !== callback) ?? [];
      if (remainingListeners.length) {
        this.progressListeners[ID] = remainingListeners;
      } else {
        delete this.progressListeners[ID];
      }
    };
  }

  async cancelUploadFile(uri: string) {
    const data = await this.loadData();
    const newData = {
      pendingUploads: data.pendingUploads.map((pending) =>
        pending.sourceUri === uri ? { ...pending, sessionUri: CANCELED_UPLOAD_URI } : pending,
      ),
    };
    await this.saveData(newData);
  }

  async uploadFile(
    uri: string,
    sessionUri?: SessionUri,
    cacheOptions?: { cacheKey: string; metaData: unknown },
  ) {
    const fileName = uri.split('/').reverse()[0];
    let blob = await this.getBlobForUri(uri);

    // Workaround for https://github.com/facebook/react-native/issues/27099
    if (Platform.OS === 'ios' && blob.type === 'image/jpeg') {
      const originalUri = uri;
      // multiple files may have same filename but different path, we don't want those to clash
      uri = `${FileSystem.cacheDirectory}resumableUploadManager-${computeMd5(
        uri,
      )}-${fileName}.toupload`;
      await FileSystem.copyAsync({ from: originalUri, to: uri });
      blob = new Blob([await this.getBlobForUri(uri)], { type: 'image/jpeg' });
    }

    const ID = uuid() as GQLUUID;
    try {
      sessionUri = sessionUri ?? (await this.uploader.initializeResumableUpload(blob, fileName));
      const data = await this.loadData();
      await this.saveData({
        pendingUploads: [
          ...data.pendingUploads,
          {
            ID,
            sourceUri: uri,
            sessionUri,
            cacheKey: cacheOptions?.cacheKey,
            metaData: cacheOptions?.metaData,
          },
        ],
      });
    } catch (e) {
      Sentry.captureException(e);
      throw e;
    }
    this.startUploadWithRetries(ID, 0);
    return ID;
  }
}

export const resumableUploadManager = new ResumableUploadManager({
  uploader: GcsResumableUploader,
});

export const ResumableUploadManagerContext = createContext(resumableUploadManager);
