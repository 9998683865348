import { SafeAreaView } from 'react-native';

import { HeaderButtons, HeaderItem } from '../components/HeaderButtons';
import { Heading } from '../components/Text';
import { View } from '../components/View';
import { APPROX_STATUSBAR_HEIGHT } from '../constants';
import { Color, useTheme } from '../styles';

export function TabHeader({
  heading,
  headerRight,
  borderBottom,
}: {
  heading: string;
  headerRight?: React.ReactNode;
  borderBottom?: boolean;
}) {
  const { theme } = useTheme();
  return (
    <SafeAreaView style={{ paddingTop: APPROX_STATUSBAR_HEIGHT }}>
      <View
        row
        style={[
          { paddingTop: 30, justifyContent: 'space-between', paddingBottom: 10 },
          borderBottom ? { borderBottomWidth: 1, borderColor: theme.color.gray600 } : null,
        ]}
      >
        {headerRight ? (
          <HeaderButtons>
            <View
              accessibilityElementsHidden
              pointerEvents="none"
              importantForAccessibility="no-hide-descendants"
              style={{ opacity: 0 }}
            >
              <HeaderItem
                accessibilityLabel={undefined}
                iconName="edit"
                title=""
                onPress={() => {}}
                style={{ opacity: 0 }}
                disabled // opacity is controlled by HeaderItem if the item is not disabled
                color={Color.styleGuide.Gray5}
              />
            </View>
          </HeaderButtons>
        ) : (
          <View />
        )}
        <Heading text={heading} style={{ zIndex: 2 }} level={1} textAlign="center" />
        {headerRight ? <HeaderButtons>{headerRight}</HeaderButtons> : <View />}
      </View>
    </SafeAreaView>
  );
}
