import { createContext, ReactNode, useContext, useEffect, useMemo, useRef, useState } from 'react';
import { AppState, AppStateStatus } from 'react-native';

import NotConnected from '../assets/notconnected.svg';
import { Button } from '../components/Button';
import { Icon } from '../components/Icon';
import { MandatoryModal } from '../components/Modal';
import { Text } from '../components/Text';
import { View } from '../components/View';
import { addBreadcrumb } from '../lib/log';
// import { resumableUploadManager } from '../lib/resumableUploadManager';
import NetInfo, { fetchIsConnected } from '../lib/NetInfo';
import { Shadow } from '../styles';

const InternetConnectivityContext = createContext<{ isInternetReachable: boolean | null }>({
  isInternetReachable: null,
});

export function useInternetConnectivityContext() {
  return useContext(InternetConnectivityContext);
}

export function InternetConnectivityProvider({
  children,
  forceOffline,
  onReconnect,
}: {
  children: ReactNode;
  forceOffline: boolean;
  onReconnect: () => void;
}) {
  const [isInternetReachable, setIsInternetReachable] = useState<boolean | null>(null);
  const [showModal, setShowModal] = useState(false);
  const forceOfflineRef = useRef(forceOffline);
  forceOfflineRef.current = forceOffline;
  const onReconnectRef = useRef(onReconnect);
  onReconnectRef.current = onReconnect;

  useEffect(() => {
    let notifyUserTimeout: NodeJS.Timeout;

    function isReachable() {
      if (!forceOfflineRef.current) {
        setShowModal(false);
        setIsInternetReachable(true);
        onReconnectRef.current();
      }
    }

    function tryNetworkFetch() {
      fetchIsConnected().then((isConnected) => {
        if (isConnected) {
          isReachable();
        } else {
          setShowModal(true);
          setIsInternetReachable(false);
        }
      });
    }

    const remove = NetInfo.addEventListener((state) => {
      clearTimeout(notifyUserTimeout);
      addBreadcrumb({ category: 'net-info', data: { state }, level: 'debug' });

      if (state.isInternetReachable === false) {
        notifyUserTimeout = setTimeout(() => {
          tryNetworkFetch();
          // This timeout helps prevent the offline state from flapping in a way that's visible to the user
        }, 10000);
      } else if (state.isInternetReachable === true) {
        isReachable();
      }
    });

    const onAppState = (status: AppStateStatus) => {
      if (status === 'active') {
        tryNetworkFetch();
      }
    };

    const appStateListener = AppState.addEventListener('change', onAppState);

    return () => {
      appStateListener.remove();
      clearTimeout(notifyUserTimeout);
      remove();
    };
  }, []);

  useEffect(() => {
    if (forceOffline) {
      setShowModal(true);
      setIsInternetReachable(false);
    } else {
      setShowModal(false);
      setIsInternetReachable(true);
    }
  }, [forceOffline]);

  const value = useMemo(() => ({ isInternetReachable }), [isInternetReachable]);

  return (
    <>
      <InternetConnectivityContext.Provider value={value}>
        {children}
      </InternetConnectivityContext.Provider>
      {isInternetReachable || isInternetReachable === null ? null : showModal ? (
        <MandatoryModal visible={true}>
          <View spacing={16} style={{ alignItems: 'center' }}>
            <NotConnected accessibilityLabel={undefined} />
            <Text text="You're offline" weight="bold" size={21} />
            <Text
              text={`Try reconnecting your wifi or data.

You'll be able to access things you've already done, but not do new things while you're offline.`}
              textAlign="center"
            />
            <Button
              text="OK"
              variant="solid"
              onPress={() => setShowModal(false)}
              alignSelf="center"
              testID="InternetConnectivityProvider_acknowledgeButton"
            />
          </View>
        </MandatoryModal>
      ) : (
        <View
          pointerEvents="none"
          style={{
            justifyContent: 'center',
            alignItems: 'center',
            position: 'absolute',
            top: 140,
            width: '100%',
          }}
        >
          <View
            testID="InternetConnectivityProvider_banner"
            style={[
              {
                backgroundColor: '#932a2a',
                borderRadius: 5,
                paddingHorizontal: 10,
                paddingVertical: 5,
              },
              Shadow.low,
            ]}
            row
            spacing={8}
          >
            <Icon name="cloud-offline" color="white" />
            <Text text="Currently offline" color="white" weight="semibold" />
          </View>
        </View>
      )}
    </>
  );
}
