import { useMutation } from '@apollo/client';
import { useNavigationState } from '@react-navigation/native';
import { useRef } from 'react';
import { TextInput as RNTextInput } from 'react-native';

import { graphql } from '@oui/lib/src/graphql/tada';

import { AuthScreenContainer } from '../components/AuthScreenContainer';
import { Button } from '../components/Button';
import { ErrorPresenter } from '../components/ErrorPresenter';
import { Link } from '../components/Link';
import { Text } from '../components/Text';
import { TextInput } from '../components/TextInput';
import { View } from '../components/View';
import { useForm } from '../hooks/useForm';
import { signOut } from '../lib/auth';
import { useI18n } from '../lib/i18n';
import { logEvent } from '../lib/log';
import Sentry from '../sentry';
import { Color } from '../styles';
import { AuthScreenProps } from '../types/navigation';

const ResetPasswordMutation = graphql(`
  mutation ResetPassword($token: String!, $password: String!) {
    updateOuiUserWithToken(value: $token, input: { password: $password }) {
      username
    }
  }
`);

export default function ResetPassword(props: AuthScreenProps<'ResetPassword'>) {
  const [resetPassword, { error }] = useMutation(ResetPasswordMutation);
  const { bind, data, validate, humanErrors } = useForm<{
    password: string;
    passwordConfirmation: string;
  }>({
    password: '',
    passwordConfirmation: '',
  });
  const hasLoginInHistory = useNavigationState((s) => {
    return s.routes.find((r) => r.name === 'Login');
  });
  const passwordConfirmationInputRef = useRef<RNTextInput>(null);
  const { $t } = useI18n();

  const request = async () => {
    if (validate()) {
      await signOut();
      logEvent('reset_password');

      return resetPassword({
        variables: { password: data.password, token: props.route.params?.token ?? '' },
      })
        .then((result) => {
          if (result.data?.updateOuiUserWithToken) {
            if (hasLoginInHistory) {
              props.navigation.navigate('Login', { resetPassword: 'true' });
            } else {
              props.navigation.replace('Login', { resetPassword: 'true' });
            }
          }
        })
        .catch((e) => {
          Sentry.captureException(e);
        });
    }

    return;
  };

  return (
    <AuthScreenContainer
      heading={$t({ id: 'ResetPassword_heading', defaultMessage: 'Choose a new password' })}
    >
      <View spacing={10}>
        {Object.keys(humanErrors).length ? (
          <ErrorPresenter formErrors={humanErrors} />
        ) : (
          <Text
            text={$t({
              id: 'ResetPassword_description',
              defaultMessage: "You're almost there. Enter a new password to update your account.",
            })}
            size={16}
            style={{ marginBottom: 20, textAlign: 'center' }}
          />
        )}
        <TextInput
          {...bind('password', {
            label: $t({ id: 'ResetPassword_passwordLabel', defaultMessage: 'New password' }),
            validator: [{ type: 'present' }, { type: 'length', minimum: 8 }],
          })}
          hint={$t({
            id: 'ResetPassword_passwordHint',
            defaultMessage: 'Must be at least 8 characters',
          })}
          secureTextEntry={true}
          returnKeyType="done"
          onSubmitEditing={() => {
            passwordConfirmationInputRef.current?.focus();
          }}
          textContentType="newPassword"
          testID="ResetPassword_password"
        />
        <TextInput
          {...bind('passwordConfirmation', {
            label: $t({
              id: 'ResetPassword_passwordConfirmationLabel',
              defaultMessage: 'Confirm password',
            }),
            validator: [
              (val) => {
                if (data.password !== val) {
                  return $t({
                    id: 'ResetPassword_passwordMismatchError',
                    defaultMessage: 'Your password and password confirmation do not match',
                  });
                }
                return;
              },
            ],
          })}
          ref={passwordConfirmationInputRef}
          secureTextEntry={true}
          returnKeyType="done"
          onSubmitEditing={request}
          textContentType="newPassword"
          testID="ResetPassword_passwordConfirmation"
        />
        <Button
          onPress={request}
          disabled={!data.password || !data.passwordConfirmation}
          alignSelf="center"
          text={$t({ id: 'ResetPassword_submitButton', defaultMessage: 'Set password' })}
          testID="ResetPassword_submit"
        />
        {error ? (
          <View spacing={10}>
            <Text
              text={$t({
                id: 'ResetPassword_error',
                defaultMessage:
                  "We couldn't reset your password. Your token may have expired. Please request a new reset password email.",
              })}
              color={Color.error}
            />
            <Link
              onPress={() => props.navigation.navigate('RequestResetPassword', {})}
              style={{ marginLeft: 10 }}
              text={$t({
                id: 'ResetPassword_requestNewEmailButton',
                defaultMessage: 'Request another email',
              })}
            />
          </View>
        ) : null}
      </View>
    </AuthScreenContainer>
  );
}
