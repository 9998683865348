import { useQuery } from '@apollo/client';
import { SafeAreaView } from 'react-native';

import { ContentType, ProductVariant, ProductVersion } from '@oui/lib/src/types';

import { useCurrentPatient } from '../../../hooks/useCurrentUser';
import { ActivityIndicator } from '../../ActivityIndicator';
import { ScrollView } from '../../ScrollView';
import { View } from '../../View';
import { SessionActivities } from '../Activities/Activities';
import { MarkComplete } from '../MarkComplete/MarkComplete';
import { SessionVideos, SessionVideosQuery } from '../Videos/Videos';

export function VideosActivities({
  testID,
  contentType,
  onComplete,
}: {
  testID?: string;
  contentType: ContentType;
  onComplete: () => void;
}) {
  const { user } = useCurrentPatient();

  const productVariant = user?.role.product.slug || ProductVariant.AVIVA_INPATIENT;
  const productVersion = user?.productVersion || ProductVersion.AVIVA_2_0;
  const { loading } = useQuery(SessionVideosQuery, {
    variables: {
      contentType,
      productVariant,
      productVersion,
    },
  });

  return (
    <SafeAreaView style={{ flex: 1 }}>
      <ScrollView testID={testID} contentContainerStyle={{ padding: 20 }}>
        {loading ? (
          <ActivityIndicator />
        ) : (
          <View spacing={50}>
            <SessionVideos contentType={contentType} />
            <SessionActivities contentType={contentType} />
            <MarkComplete contentType={contentType} onComplete={onComplete} />
          </View>
        )}
      </ScrollView>
    </SafeAreaView>
  );
}
