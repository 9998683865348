import AsyncStorage from '@react-native-async-storage/async-storage';
import { ParamListBase } from '@react-navigation/native';
import { createStackNavigator } from '@react-navigation/stack';
import { createContext, useCallback, useState } from 'react';
import { Platform, StyleSheet } from 'react-native';

import { useAppContext } from '../components/AppContext';
import { KeyboardAvoidingView } from '../components/KeyboardAvoidingView';
import { Reauthenticate } from '../components/Reauthenticate';
import { View } from '../components/View';
import { useAppState } from '../hooks/useAppState';
import { useIsLoggedIn } from '../hooks/useIsLoggedIn';
import { pauseInteractionForMillis } from '../lib/pauseInteraction';

export const SessionTimeoutNavigatorKeyboardAvoidingViewContext = createContext({
  setEnabled: (enabled: boolean) => {},
});

export function createSessionTimeoutStackNavigator<T extends ParamListBase>(timeout: number) {
  const { Navigator, Screen } = createStackNavigator<T>();
  return {
    Navigator: ((props) => {
      const { navigationContainer } = useAppContext();
      const isLoggedIn = useIsLoggedIn();
      const [enabled, setEnabled] = useState(true);
      const [showReauthentication, setShowReauthentication] = useState(false);

      useAppState(
        useCallback(
          (nextAppState) => {
            if (nextAppState === 'active') {
              AsyncStorage.getItem('lastSeen').then((lastSeenStr) => {
                const lastSeen = Number.parseInt(lastSeenStr || '');
                if (!Number.isFinite(lastSeen) || Date.now() - lastSeen > timeout) {
                  if (isLoggedIn) {
                    pauseInteractionForMillis(500);
                    setShowReauthentication(true);
                  }
                }
              });
            } else if (!showReauthentication) {
              AsyncStorage.setItem('lastSeen', Date.now().toString());
            }
          },
          [isLoggedIn, showReauthentication],
        ),
      );

      const inner = <Navigator {...props}>{props.children}</Navigator>;

      return (
        <>
          <View
            importantForAccessibility={showReauthentication ? 'no-hide-descendants' : undefined}
            accessibilityElementsHidden={showReauthentication}
            style={{ flex: 1 }}
          >
            <SessionTimeoutNavigatorKeyboardAvoidingViewContext.Provider value={{ setEnabled }}>
              {isLoggedIn && Platform.OS !== 'android' ? (
                <KeyboardAvoidingView behavior="padding" style={{ flex: 1 }} enabled={enabled}>
                  {inner}
                </KeyboardAvoidingView>
              ) : (
                inner
              )}
            </SessionTimeoutNavigatorKeyboardAvoidingViewContext.Provider>
          </View>
          {showReauthentication ? (
            <View style={[StyleSheet.absoluteFillObject]}>
              <Reauthenticate
                onSuccess={() => setShowReauthentication(false)}
                onLogout={(forgotPassword) => {
                  setTimeout(() => {
                    setShowReauthentication(false);
                    if (forgotPassword) {
                      // @ts-ignore
                      navigationContainer?.navigate('RequestResetPassword', {});
                    }
                  }, 100);
                }}
              />
            </View>
          ) : null}
        </>
      );
    }) as typeof Navigator,
    Screen,
  };
}
