import { useIsFocused, useNavigation, useRoute } from '@react-navigation/native';
import { useRef, useState } from 'react';
import { TextInput as RNTextInput, StatusBar } from 'react-native';

import { AuthScreenContainer } from '../components/AuthScreenContainer';
import { Button } from '../components/Button';
import { ErrorPresenter } from '../components/ErrorPresenter';
import { Link } from '../components/Link';
import { Text } from '../components/Text';
import { EmailInput, TextInput } from '../components/TextInput';
import { View } from '../components/View';
import { useForm } from '../hooks/useForm';
import { useLogout } from '../hooks/useLogout';
import { getIsLoggedIn, login } from '../lib/auth';
import { useI18n } from '../lib/i18n';
import { firebaseErrors, isFirebaseErrorKey } from '../lib/isFirebaseErrorKey';
import Sentry from '../sentry';
import { Color } from '../styles';
import { AuthScreenProps } from '../types/navigation';

export default function Login() {
  // prefer hooks over component props because createSwitchNavigator from @rn/compat doesn't pass
  // the new param shape down to screens and in the staff app we navigate directly here instead
  // of a "Welcome" screen"
  const navigation = useNavigation<AuthScreenProps<'Login'>['navigation']>();
  const route = useRoute<AuthScreenProps<'Login'>['route']>();
  const props = { navigation, route };
  const [isLoggingIn, setIsLoggingIn] = useState(false);
  const isFocused = useIsFocused();

  const { bind, data, clear, validate, humanErrors } = useForm<{ email: string; password: string }>(
    {
      email: '',
      password: '',
    },
  );
  const [errorCode, setErrorCode] = useState('');
  const logout = useLogout();
  const { $t } = useI18n();

  const emailInputRef = useRef<RNTextInput>(null);
  const passwordInputRef = useRef<RNTextInput>(null);

  const onLogin = () => {
    setErrorCode('');
    if (!validate()) return;
    setIsLoggingIn(true);
    const promise = login(data.email.trim(), data.password);

    return promise
      .then(() => {
        clear();
      })
      .catch((e: unknown) => {
        if (e instanceof Error) {
          setErrorCode(e.message);
        }
        Sentry.captureException(e);
      })
      .then(() => {
        setIsLoggingIn(false);
      });
  };

  const errorString = errorCode
    ? isFirebaseErrorKey(errorCode)
      ? $t(firebaseErrors[errorCode])
      : errorCode
    : undefined;

  return (
    <AuthScreenContainer
      testID="Login"
      heading={$t({ id: 'Login_heading', defaultMessage: 'Welcome back' })}
    >
      {isFocused ? <StatusBar backgroundColor="white" barStyle="dark-content" /> : null}
      <ErrorPresenter
        formErrors={humanErrors}
        errorString={errorString}
        style={{ marginBottom: 10 }}
      />
      <View spacing={10} style={{ flexGrow: 1 }}>
        {props.route.params?.resetPassword === 'true' ? (
          <Text
            text={$t({
              id: 'Login_passwordResetHint',
              defaultMessage: 'Password reset successfully. Please log in.',
            })}
            testID="Login_passwordResetHint"
            color={Color.success}
            textAlign="center"
          />
        ) : null}
        <View>
          <EmailInput
            {...bind('email', {
              label: $t({ id: 'Login_emailLabel', defaultMessage: 'Email' }),
              validator: { type: 'email' },
            })}
            returnKeyType="next"
            testID="Login_email"
            ref={emailInputRef}
            onSubmitEditing={() => passwordInputRef.current?.focus()}
          />
        </View>
        <TextInput
          secureTextEntry={true}
          placeholder={$t({ id: 'Login_passwordPlaceholder', defaultMessage: 'Password' })}
          textContentType="password"
          {...bind('password', {
            label: $t({ id: 'Login_passwordLabel', defaultMessage: 'Password' }),
          })}
          returnKeyType="done"
          ref={passwordInputRef}
          onSubmitEditing={onLogin}
          testID="Login_password"
          error={errorString}
        />
        <Link
          testID="Login_forgotPassword"
          onPress={() => {
            if (getIsLoggedIn()) {
              logout().then(() => {
                setTimeout(() => {
                  props.navigation.navigate('RequestResetPassword', {});
                }, 100);
              });
            } else {
              props.navigation.navigate('RequestResetPassword', {});
            }
          }}
          style={{ marginLeft: 10 }}
          text={$t({ id: 'Login_forgotPasswordButton', defaultMessage: 'Forgot password?' })}
        />
        <Button
          onPress={onLogin}
          loading={isLoggingIn}
          disabled={!data.password || !data.email}
          alignSelf="flex-end"
          iconRight="arrow-right"
          testID="Login_submit"
          text={$t({ id: 'Login_loginButton', defaultMessage: 'Login' })}
        />
      </View>
    </AuthScreenContainer>
  );
}
