import { useRoute } from '@react-navigation/native';

import { Text } from '../components/Text';
import { View } from '../components/View';
import { Color } from '../styles';
import { StackScreenRouteProp } from '../types/navigation';

export function ChatHeader() {
  const route = useRoute<StackScreenRouteProp<'Conversation'>>();
  const title = route.params?.title ?? '';
  const sessionNum = route.params?.num ?? null;
  return (
    <View style={{ paddingTop: 80 }}>
      <Text
        text={title}
        weight="bold"
        size={22}
        style={{
          textAlign: 'center',
        }}
      />
      {sessionNum !== null ? (
        <View style={{ marginTop: 10 }}>
          <Text
            text={`Session ${sessionNum}`}
            style={{
              textAlign: 'center',
            }}
          />
          <View
            style={{
              backgroundColor: Color.secondary,
              marginLeft: -25,
              height: 2,
              width: 50,
              marginBottom: 10,
              alignSelf: 'center',
            }}
          />
        </View>
      ) : null}
    </View>
  );
}
