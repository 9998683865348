import * as Font from 'expo-font';
import * as FontServer from 'expo-font/build/server';

// used b/c nextjs require returns { default: '' }
function interopDefault<T>(obj: T): string | number | undefined {
  // @ts-ignore
  return obj.default || obj;
}

const FontMap = {
  OpenSansBold: {
    uri: interopDefault(require('../assets/font/OpenSans-Bold.ttf')),
    display: Font.FontDisplay.FALLBACK,
  },
  OpenSansRegular: {
    uri: interopDefault(require('../assets/font/OpenSans-Regular.ttf')),
    display: Font.FontDisplay.FALLBACK,
  },
  OpenSansSemiBold: {
    uri: interopDefault(require('../assets/font/OpenSans-SemiBold.ttf')),
    display: Font.FontDisplay.FALLBACK,
  },
  SourceSerifProSemiBold: {
    uri: interopDefault(require('../assets/font/SourceSerifPro-SemiBold.ttf')),
    display: Font.FontDisplay.FALLBACK,
  },
};

export function getServerResources() {
  Object.entries(FontMap).forEach(([key, source]) => {
    FontServer.registerStaticFont(key, source);
  });

  return FontServer.getServerResources().join('');
}

export function initFonts() {
  return Font.loadAsync(FontMap);
}
