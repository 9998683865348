import AsyncStorage from '@react-native-async-storage/async-storage';
import * as LocalAuthentication from 'expo-local-authentication';
import * as SecureStore from 'expo-secure-store';
import { useEffect, useRef, useState } from 'react';
import {
  TextInput as RNTextInput,
  ScrollView,
  StatusBar,
  TouchableWithoutFeedback,
} from 'react-native';
import { KeyboardAwareScrollView } from 'react-native-keyboard-aware-scroll-view';

import Biometric from '../assets/biometric.svg';
import FacialRecognition from '../assets/facialRecognition.svg';
import Fingerprint from '../assets/fingerprint.svg';
import { AuthScreenContainer } from '../components/AuthScreenContainer';
import { Button } from '../components/Button';
import { ErrorPresenter } from '../components/ErrorPresenter';
import { Link } from '../components/Link';
import { Modal } from '../components/Modal';
import { Text } from '../components/Text';
import { TextInput } from '../components/TextInput';
import { View } from '../components/View';
import { useForm } from '../hooks/useForm';
import { useLogout } from '../hooks/useLogout';
import { AuthenticationTypes, useReauthenticationState } from '../hooks/useReauthenticationState';
import { reauthenticate } from '../lib/auth';
import { useI18n } from '../lib/i18n';
import { firebaseErrors, isFirebaseErrorKey } from '../lib/isFirebaseErrorKey';
import { addBreadcrumb } from '../lib/log';
import Sentry from '../sentry';
import { Color } from '../styles';

export function ReauthenticationModal(props: {
  hint?: string;
  onSuccess: (password: string) => void;
  onRequestClose: () => void;
}) {
  const [errorCode, setErrorCode] = useState('');
  const { bind, data } = useForm<{ password: string }>({ password: '' });
  const { $t } = useI18n();
  const onLogin = async () => {
    try {
      await reauthenticate('', data.password);
      props.onSuccess(data.password);
    } catch (e: unknown) {
      const err = e as Error;
      if (err.message == 'INVALID_PASSWORD') setErrorCode('INVALID_PASSWORD_NO_EMAIL');
      setErrorCode(err.message);
    }
  };

  const errorString = errorCode
    ? isFirebaseErrorKey(errorCode)
      ? $t(firebaseErrors[errorCode])
      : errorCode
    : undefined;

  return (
    <Modal heading="Enter your password" visible={true} onRequestClose={props.onRequestClose}>
      <View spacing={12}>
        {props.hint ? <Text text={props.hint} /> : null}
        <TextInput
          secureTextEntry={true}
          placeholder={$t({ id: 'Login_passwordPlaceholder', defaultMessage: 'Password' })}
          textContentType="password"
          {...bind('password', {
            label: $t({ id: 'Login_passwordLabel', defaultMessage: 'Password' }),
          })}
          error={errorString}
          returnKeyType="done"
          onSubmitEditing={onLogin}
          testID="Login_password"
        />
        <Button
          text={$t({ id: 'ReauthenticationModal_submitButton', defaultMessage: 'Submit' })}
          testID="ReauthenticationModal_submitButton"
          onPress={onLogin}
        />
      </View>
    </Modal>
  );
}

function PINForm(props: { pin: string; onSetPIN: (newPin: string) => void }) {
  const inputRef = useRef<RNTextInput>(null);
  const buttonSize = 45;

  function NumberBlank({ i }: { i: number }) {
    return (
      <TouchableWithoutFeedback onPress={() => inputRef.current?.focus()}>
        <View
          style={{
            borderRadius: 30,
            borderWidth: 1,
            borderColor: '#C6C6D4',
            width: buttonSize,
            height: buttonSize,
            alignItems: 'center',
          }}
          key={i}
        >
          <View flex={1} style={{ justifyContent: 'center' }}>
            {i < props.pin.length ? (
              <View style={{ width: 16, height: 16, borderRadius: 10, backgroundColor: 'black' }} />
            ) : null}
          </View>
        </View>
      </TouchableWithoutFeedback>
    );
  }

  return (
    <View row spacing={10}>
      <RNTextInput
        value={props.pin}
        ref={inputRef}
        style={{
          position: 'absolute',
          opacity: 0,
        }}
        keyboardType="number-pad"
        onChangeText={props.onSetPIN}
        autoFocus
      />
      <NumberBlank i={0} />
      <NumberBlank i={1} />
      <NumberBlank i={2} />
      <NumberBlank i={3} />
    </View>
  );
}

function StartLocalAuthentication(props: { onStart: () => unknown }) {
  useEffect(() => {
    setTimeout(() => {
      props.onStart();
    }, 500); // give detox a little bit of time to synchronize if we've just launched the app
    // eslint-disable-next-line
  }, []);
  return null;
}

// ../components/ScrollView uses useIsFocused internally which depends on react-navigation mounting
// successfully. Since in an error state, we may not be able to guarantee that, we use KeyboardAwareScrollView directly
// A standard ScrollView is insufficient since it doesn't handle keyboard behavior, prevent the user from scrolling fully
function CustomKeyboardAwareScrollView(props: React.ComponentProps<typeof ScrollView>) {
  return (
    <KeyboardAwareScrollView
      showsHorizontalScrollIndicator={!global.e2e}
      showsVerticalScrollIndicator={!global.e2e}
      scrollIndicatorInsets={{ right: Number.MIN_VALUE }}
      // give extra padding / cushion so inputs aren't crammed against the keyboard
      extraHeight={150}
      {...props}
      keyboardShouldPersistTaps="handled"
    />
  );
}

export function Reauthenticate(props: {
  onSuccess: () => void | Promise<void>;
  onLogout: (forgotPassword?: boolean) => void | Promise<void>;
}) {
  const [isLoggingIn, setIsLoggingIn] = useState(false);

  const { isLoading, enrolledTypes } = useReauthenticationState();
  const { bind, data, clear, validate, humanErrors } = useForm<{ email: string; password: string }>(
    {
      email: '',
      password: '',
    },
  );
  const [errorCode, setErrorCode] = useState('');
  const logout = useLogout();
  const { $t } = useI18n();

  const passwordInputRef = useRef<RNTextInput>(null);

  function onSuccess() {
    addBreadcrumb({
      category: 'reauthenticate',
      message: 'reauthenticate-success',
    });

    AsyncStorage.setItem('lastSeen', Date.now().toString());
    return props.onSuccess();
  }

  const onLogin = () => {
    setErrorCode('');
    if (!validate()) return;
    setIsLoggingIn(true);
    const promise = reauthenticate(data.email.trim(), data.password).then(() => {
      return onSuccess();
    });

    return promise
      .then(() => {
        clear();
      })
      .catch((e) => {
        setErrorCode(e.message);
        Sentry.captureException(e);
      })
      .then(() => {
        setIsLoggingIn(false);
      });
  };

  const [localAuthenticationError, setLocalAuthenticationError] = useState(false);
  const [bypassLocalAuthentication, setBypassLocalAuthentication] = useState(false);
  const [forcePIN, setForcePIN] = useState(false);
  const [pinData, setPinData] = useState('');
  const [isLocalAuthenticating, setIsLocalAuthenticating] = useState(false);

  if (isLoading) return null;
  if (enrolledTypes.length && !bypassLocalAuthentication) {
    const enrolledType =
      forcePIN && enrolledTypes.includes(AuthenticationTypes.PIN)
        ? AuthenticationTypes.PIN
        : [...enrolledTypes].sort()[enrolledTypes.length - 1];
    const tryLocalAuth = () => {
      addBreadcrumb({
        category: 'auth',
        message: 'try-local-auth',
        data: { enrolledTypes, forcePIN, enrolledType },
      });

      if (enrolledType === AuthenticationTypes.PIN) {
        return SecureStore.getItemAsync('reauthPIN')
          .then((savedPin) => {
            addBreadcrumb({
              category: 'auth',
              message: 'local-auth-result',
              data: { result: savedPin === pinData },
            });
            if (savedPin === pinData) {
              onSuccess();
            } else {
              setPinData('');
              setLocalAuthenticationError(true);
            }
          })
          .catch((e) => {
            Sentry.captureException(e);
          });
      }

      if (isLocalAuthenticating) return;
      setIsLocalAuthenticating(true);
      return LocalAuthentication.authenticateAsync({
        fallbackLabel: '',
        promptMessage: $t({
          id: 'Login_localAuthentication_promptMessage',
          defaultMessage: 'Login to app',
        }),
        requireConfirmation: false,
      })
        .then((result) => {
          addBreadcrumb({
            category: 'auth',
            message: 'local-auth-result',
            data: { result },
          });
          setIsLocalAuthenticating(false);
          if (result.success) {
            onSuccess();
          } else {
            setLocalAuthenticationError(true);
          }
        })
        .catch((e) => {
          Sentry.captureException(e);
        });
    };

    function getDescription() {
      switch (enrolledType) {
        case AuthenticationTypes.PIN: {
          return $t({
            id: 'Login_localAuthentication_pinDescription',
            defaultMessage: 'Enter PIN to login to app',
          });
        }
        case AuthenticationTypes.BIOMETRIC: {
          return $t({
            id: 'Login_localAuthentication_biometricDescription',
            defaultMessage: 'Use your fingerprint or face to login',
          });
        }
        case AuthenticationTypes.IRIS: {
          return $t({
            id: 'Login_localAuthentication_irisDescription',
            defaultMessage: 'Use your irises to login',
          });
        }
        case AuthenticationTypes.FINGERPRINT: {
          return $t({
            id: 'Login_localAuthentication_fingerprintDescription',
            defaultMessage: 'Touch sensor to login to app',
          });
        }
        case AuthenticationTypes.FACIAL_RECOGNITION: {
          return $t({
            id: 'Login_localAuthentication_faceDescription',
            defaultMessage: 'Use Face ID to login to app',
          });
        }
      }
    }

    function getLoginFailedText() {
      switch (enrolledType) {
        case AuthenticationTypes.PIN: {
          return $t({
            id: 'Login_localAuthentication_pinFailed',
            defaultMessage: 'The PIN is invalid',
          });
        }
        case AuthenticationTypes.BIOMETRIC: {
          return $t({
            id: 'Login_localAuthentication_biometricFailed',
            defaultMessage: 'Not recognized',
          });
        }
        case AuthenticationTypes.IRIS: {
          return $t({
            id: 'Login_localAuthentication_irisFailed',
            defaultMessage: 'Not recognized',
          });
        }
        case AuthenticationTypes.FINGERPRINT: {
          return $t({
            id: 'Login_localAuthentication_fingerprintFailed',
            defaultMessage: 'Fingerprint not recognized',
          });
        }
        case AuthenticationTypes.FACIAL_RECOGNITION: {
          return $t({
            id: 'Login_localAuthentication_faceFailed',
            defaultMessage: 'Face not recognized',
          });
        }
      }
    }

    return (
      <AuthScreenContainer
        heading={$t({ id: 'Login_heading', defaultMessage: 'Welcome back' })}
        _scrollView={CustomKeyboardAwareScrollView}
      >
        {(
          [
            AuthenticationTypes.FINGERPRINT,
            AuthenticationTypes.BIOMETRIC,
            AuthenticationTypes.FACIAL_RECOGNITION,
            AuthenticationTypes.IRIS,
          ] as Array<typeof enrolledType>
        ).includes(enrolledType) ? (
          <StartLocalAuthentication onStart={tryLocalAuth} />
        ) : null}
        <View spacing={30} style={{ marginVertical: 20, flexGrow: 1, alignItems: 'center' }}>
          <Text
            testID={`Login_reauthenticateDescription_${enrolledType}`}
            text={getDescription()}
            weight="semibold"
          />
          {enrolledType === AuthenticationTypes.PIN ? (
            <PINForm pin={pinData} onSetPIN={setPinData} />
          ) : null}
          {enrolledType === AuthenticationTypes.FINGERPRINT ? (
            <Fingerprint
              accessibilityLabel={undefined}
              color={
                isLocalAuthenticating
                  ? Color.styleGuide.LogoCyan
                  : localAuthenticationError
                    ? Color.error
                    : Color.styleGuide.Gray5
              }
            />
          ) : null}
          {enrolledType === AuthenticationTypes.BIOMETRIC ? (
            <Biometric accessibilityLabel={undefined} />
          ) : null}
          {enrolledType === AuthenticationTypes.FACIAL_RECOGNITION ? (
            <FacialRecognition accessibilityLabel={undefined} />
          ) : null}
          {localAuthenticationError ? (
            <Text
              text={getLoginFailedText()}
              color={Color.error}
              testID="Login_reauthenticateFailed"
            />
          ) : null}
          {localAuthenticationError ? (
            <Button
              disabled={isLocalAuthenticating}
              onPress={tryLocalAuth}
              text={$t({ id: 'Login_reauthenticate_tryAgain', defaultMessage: 'Try again' })}
              testID="Login_reauthenticate_tryAgain"
              iconRight="arrow-right"
            />
          ) : enrolledType === AuthenticationTypes.PIN ? (
            <Button
              disabled={isLocalAuthenticating}
              onPress={tryLocalAuth}
              text={$t({
                id: 'Login_reauthenticate_usePinButton',
                defaultMessage: 'Login with PIN',
              })}
              testID="Login_reauthenticate_usePinButton"
              iconRight="arrow-right"
            />
          ) : null}
          <View flex={1} style={{ justifyContent: 'center' }}>
            {enrolledTypes.includes(AuthenticationTypes.PIN) &&
            enrolledType !== AuthenticationTypes.PIN ? (
              <Button
                alignSelf="center"
                variant="text"
                onPress={() => setForcePIN(true)}
                text={$t({
                  id: 'Login_reauthenticate_usePinFallbackButton',
                  defaultMessage: 'Login with PIN',
                })}
                testID="Login_reauthenticate_usePinFallbackButton"
              />
            ) : (
              <Button
                alignSelf="center"
                variant="text"
                onPress={() => setBypassLocalAuthentication(true)}
                text={$t({
                  id: 'Login_reauthenticate_usePasswordButton',
                  defaultMessage: 'Type password',
                })}
                testID="Login_reauthenticate_usePasswordButton"
              />
            )}
          </View>
        </View>
      </AuthScreenContainer>
    );
  }

  const errorString = errorCode
    ? isFirebaseErrorKey(errorCode)
      ? $t(firebaseErrors[errorCode])
      : errorCode
    : undefined;

  return (
    <AuthScreenContainer
      heading={$t({ id: 'Login_heading', defaultMessage: 'Welcome back' })}
      _scrollView={CustomKeyboardAwareScrollView}
    >
      <StatusBar backgroundColor="white" barStyle="dark-content" />
      <ErrorPresenter
        formErrors={humanErrors}
        errorString={errorString}
        style={{ marginBottom: 10 }}
      />
      <View spacing={10} style={{ flexGrow: 1 }}>
        <Text
          text={$t({
            id: 'Login_description',
            defaultMessage:
              "You've been gone for a bit. For your privacy please re-enter your password.",
          })}
          style={{ marginBottom: 20, textAlign: 'center' }}
        />
        <TextInput
          secureTextEntry={true}
          placeholder={$t({ id: 'Login_passwordPlaceholder', defaultMessage: 'Password' })}
          textContentType="password"
          {...bind('password', {
            label: $t({ id: 'Login_passwordLabel', defaultMessage: 'Password' }),
          })}
          returnKeyType="done"
          ref={passwordInputRef}
          onSubmitEditing={onLogin}
          testID="Login_password"
          error={errorString}
        />
        <Link
          testID="Login_forgotPassword"
          onPress={async () => {
            await logout();
            await props.onLogout(true);
          }}
          style={{ marginLeft: 10 }}
          text={$t({ id: 'Login_forgotPasswordButton', defaultMessage: 'Forgot password?' })}
        />
        <Button
          onPress={onLogin}
          loading={isLoggingIn}
          disabled={!data.password}
          alignSelf="flex-end"
          iconRight="arrow-right"
          testID="Login_submit"
          text={$t({ id: 'Login_loginButton', defaultMessage: 'Login' })}
        />
        <View flex={1} style={{ justifyContent: 'center' }}>
          <Button
            text={$t({ id: 'Login_logoutButton', defaultMessage: 'Log out' })}
            alignSelf="center"
            onPress={async () => {
              await logout();
              await props.onLogout();
            }}
            variant="text"
          />
        </View>
      </View>
    </AuthScreenContainer>
  );
}
