import { useQuery } from '@apollo/client';
import { useNavigation } from '@react-navigation/native';

import { graphql } from '@oui/lib/src/graphql/tada';

import { useTheme } from '../../styles';
import { Button } from '../Button';
import { Heading, Label, Text } from '../Text';
import { View } from '../View';

export const LessonsLearnedQuery = graphql(`
  query LessonsLearned {
    lessonLearned {
      createdAt
      session
      text
    }
  }
`);

export const LessonsLearned = () => {
  const { theme } = useTheme();
  const { data } = useQuery(LessonsLearnedQuery);
  const navigation = useNavigation();

  return (
    <View
      style={{
        borderRadius: 20,
        backgroundColor: 'white',
        paddingVertical: 15,
        paddingHorizontal: 20,
        gap: 25,
      }}
    >
      <View
        row
        style={{
          justifyContent: 'space-between',
        }}
      >
        <Heading level={2} text="Lessons learned" color={theme.color.dark} />
        {data?.lessonLearned?.length ? (
          <Button
            variant="text"
            iconRight="edit"
            accessibilityLabel="Edit"
            onPress={() => {
              navigation.navigate('EditLessonsLearned');
            }}
            color={theme.color.gray400}
          />
        ) : null}
      </View>

      <View
        style={{
          gap: 15,
          padding: 20,
          borderRadius: 20,
          borderColor: theme.color.accentTwo300,
          borderWidth: 1,
        }}
      >
        <Label small text="Lesson from each session" color={theme.color.gray300} />

        <View
          style={{
            gap: 15,
          }}
        >
          {data?.lessonLearned?.length ? (
            data.lessonLearned.map((lesson, index) => (
              <View key={index} row style={{ alignItems: 'flex-start' }}>
                <View>
                  <Text
                    style={{
                      width: 35,
                    }}
                    text={index + 1}
                    color={theme.color.gray300}
                  />
                </View>

                <Label text={lesson.text} color={theme.color.gray200} />
              </View>
            ))
          ) : (
            <Text size={15} text="Complete session 1" color={theme.color.gray200} />
          )}
        </View>
      </View>
    </View>
  );
};
