import { Image } from 'react-native';

export function Todo() {
  return (
    <Image
      source={{
        uri: 'https://media.istockphoto.com/id/1142645029/vector/isolated-under-construction-tape.jpg?s=612x612&w=0&k=20&c=TSPfqyiy-1jVnqQqTwkUgvwPnjQvE1R5fBX86SMVmsE=',
      }}
      style={{
        width: '100%',
        height: 40,
      }}
    />
  );
}
