import { useMutation, useQuery } from '@apollo/client';
import { useNavigation, useRoute } from '@react-navigation/native';
import pickBy from 'lodash/pickBy';
import { PropsWithChildren, useCallback, useEffect, useLayoutEffect } from 'react';
import { FormProvider } from 'react-hook-form';
import { z } from 'zod';

import { AddressFormInput } from '@oui/app-core/src/components/AddressFormInput';
import { AuthenticationSettings } from '@oui/app-core/src/components/AuthenticationSettings';
import { Button } from '@oui/app-core/src/components/Button';
import { HeaderButtons, HeaderItem } from '@oui/app-core/src/components/HeaderButtons';
import { ScrollView } from '@oui/app-core/src/components/ScrollView';
import { Heading, Label, Text } from '@oui/app-core/src/components/Text';
import { UserAddress } from '@oui/app-core/src/components/UserAddress';
import { View } from '@oui/app-core/src/components/View';
import {
  Controller,
  SwitchInputRender,
  TextFormInput,
  useZodForm,
  useZodFormContext,
} from '@oui/app-core/src/form';
import { type CurrentUserQueryName } from '@oui/app-core/src/hooks/useCurrentUser';
import { useLogout } from '@oui/app-core/src/hooks/useLogout';
import { useI18n } from '@oui/app-core/src/lib/i18n';
import { useTheme } from '@oui/app-core/src/styles';
import { graphql } from '@oui/lib/src/graphql/tada';
import { UpdateUserInputSchema } from '@oui/lib/src/types';

import { RootStackScreenProps } from '@src/types/navigation';

const AccountSettingsQueryName = 'AccountSettings';
export const AccountSettingsQuery = graphql(
  `
    query AccountSettings {
      user {
        ID
        email
        givenName
        phone
        familyName
        addresses {
          city
          line
          postalCode
          state
          use
        }
        birthDate
      }
      ouiUser {
        userID
        pushNotificationsDisabled
      }
    }
  `,
  [],
);

export const SaveAccountSettingsMutation = graphql(`
  mutation SaveAccountSettings($input: MutationUpdateUserInput!) {
    updateUser(input: $input) {
      ID
    }
  }
`);

export const SetPushNotificationsDisabledMutation = graphql(`
  mutation SetPushNotificationsDisabled($pushNotificationsDisabled: Boolean!) {
    setPushNotificationsDisabled(pushNotificationsDisabled: $pushNotificationsDisabled) {
      __typename
      userID
      pushNotificationsDisabled
    }
  }
`);

const SaveAccountSettingsMutationSchema = UpdateUserInputSchema;

function Subheading(props: { text: string }) {
  const { Color } = useTheme();
  return (
    <Text
      text={props.text}
      color={Color.styleGuide.Gray3}
      style={{ marginBottom: 6 }}
      size={15}
      weight="semibold"
    />
  );
}

function Section(props: PropsWithChildren<{ title: string }>) {
  return (
    <View style={{ gap: 10 }}>
      <Heading text={props.title} level={2} />
      {props.children}
    </View>
  );
}

function AccountSection() {
  const route = useRoute<RootStackScreenProps<'Account'>['route']>();
  const { data } = useQuery(AccountSettingsQuery);
  const isEditing = route.params?.isEditing === 'true';
  const { $t } = useI18n();
  const { control } = useZodFormContext(SaveAccountSettingsMutationSchema);

  return (
    <Section title={$t({ id: 'Account_accountHeading', defaultMessage: 'Account' })}>
      {isEditing ? (
        <>
          <TextFormInput
            control={control}
            label={$t({
              id: 'Account_givenNameLabel',
              defaultMessage: 'First name',
            })}
            name="givenName"
          />
          <TextFormInput
            control={control}
            label={$t({
              id: 'Account_familyNameLabel',
              defaultMessage: 'Last name',
            })}
            name="familyName"
          />
        </>
      ) : (
        <View spacing={20}>
          <>
            <Subheading text={$t({ id: 'Account_nameLabel', defaultMessage: 'Name' })} />
            <Text
              testID="Account_name"
              text={`${data?.user?.givenName} ${data?.user?.familyName}`}
            />
          </>
          <>
            <Subheading text={$t({ id: 'Account_emailLabel', defaultMessage: 'Email' })} />
            <Text testID="Account_email" text={data?.user?.email ?? ''} />
          </>
          <AuthenticationSettings />
        </View>
      )}
    </Section>
  );
}

function ContactSection() {
  const route = useRoute<RootStackScreenProps<'Account'>['route']>();
  const { data } = useQuery(AccountSettingsQuery);
  const isEditing = route.params?.isEditing === 'true';
  const { $t } = useI18n();
  const { control } = useZodFormContext(SaveAccountSettingsMutationSchema);

  return (
    <Section title={$t({ id: 'Account_contactHeading', defaultMessage: 'Contact information' })}>
      {isEditing ? (
        <View testID="ContactSection_edit" spacing={8}>
          <AddressFormInput control={control} name="addresses.0" requiresMailingAddress={false} />
          <TextFormInput
            control={control}
            label={$t({
              id: 'Account_phoneLabel',
              defaultMessage: 'Phone number',
            })}
            placeholder={$t({
              id: 'Account_phonePlaceholder',
              defaultMessage: 'Phone number',
            })}
            autoComplete={'tel'}
            disabled={!isEditing}
            keyboardType={'phone-pad'}
            textContentType={'telephoneNumber'}
            name="phone"
          />
        </View>
      ) : data && data.user ? (
        <View spacing={20}>
          {data?.user.addresses && Object.keys(pickBy(data.user.addresses)).length ? (
            <>
              <Subheading
                text={$t({ id: 'Account_addressLabel', defaultMessage: 'Home address' })}
              />
              <UserAddress {...data.user.addresses[0]} />
            </>
          ) : null}
          <>
            <Subheading text={$t({ id: 'Account_phoneLabel', defaultMessage: 'Phone number' })} />
            <Text
              testID="ContactSection_phone"
              text={
                data.user.phone || $t({ id: 'Account_unknownValue', defaultMessage: 'Unknown' })
              }
            />
          </>
        </View>
      ) : null}
    </Section>
  );
}

const AllowPushSchema = z.object({
  allowPush: z.boolean(),
});

function NotificationSection() {
  const { $t } = useI18n();
  const { data } = useQuery(AccountSettingsQuery, { fetchPolicy: 'cache-only' });
  const [setPush] = useMutation(SetPushNotificationsDisabledMutation);
  const form = useZodForm(AllowPushSchema, {
    defaultValues: { allowPush: !data?.ouiUser?.pushNotificationsDisabled },
  });
  const { control, watch } = form;

  const allowPush = watch('allowPush');

  useEffect(() => {
    if (
      typeof allowPush === 'boolean' &&
      typeof data?.ouiUser?.pushNotificationsDisabled === 'boolean'
    ) {
      // prevent sending unecessary mutations
      if (data?.ouiUser?.pushNotificationsDisabled !== !allowPush) {
        setPush({ variables: { pushNotificationsDisabled: !allowPush } });
      }
    }
  }, [setPush, allowPush, data?.ouiUser]);

  return (
    <Section
      title={$t({
        id: 'Account_notificationsHeading',
        defaultMessage: 'Notification settings',
      })}
    >
      <View row style={{ justifyContent: 'space-between' }}>
        <Label
          text={$t({
            id: 'Account_pushNotificationsLabel',
            defaultMessage: 'Allow push notifications',
          })}
          accessibilityRole="none"
        />
        <Controller
          control={control}
          render={(props) =>
            SwitchInputRender(props, {
              accessibilityLabel: $t({
                id: 'Account_pushNotificationsLabel',
                defaultMessage: 'Allow push notifications',
              }),
            })
          }
          name="allowPush"
        />
      </View>
    </Section>
  );
}

function LegalSection() {
  const navigation = useNavigation<RootStackScreenProps<'Account'>['navigation']>();
  const route = useRoute<RootStackScreenProps<'Account'>['route']>();
  const isEditing = route.params?.isEditing === 'true';
  const { $t } = useI18n();

  return isEditing ? null : (
    <Section title={$t({ id: 'Account_legalHeading', defaultMessage: 'Legal' })}>
      <>
        <View spacing={8}>
          <Button
            variant="text"
            text={$t({
              id: 'Account_termsOfServiceLink',
              defaultMessage: 'Terms of service & privacy policy',
            })}
            onPress={() => {
              navigation.navigate('TermsAndPrivacy');
            }}
          />
        </View>
      </>
    </Section>
  );
}

export function Account(_props: {}) {
  const navigation = useNavigation<RootStackScreenProps<'Account'>['navigation']>();
  const route = useRoute<RootStackScreenProps<'Account'>['route']>();
  const { $t } = useI18n();
  const { data } = useQuery(AccountSettingsQuery);
  const [updateUser] = useMutation(SaveAccountSettingsMutation);
  const logout = useLogout();
  const isEditing = route.params?.isEditing === 'true';
  const { theme } = useTheme();

  const form = useZodForm(SaveAccountSettingsMutationSchema, {
    defaultValues: {
      givenName: data?.user?.givenName,
      familyName: data?.user?.familyName,
      birthDate: data?.user?.birthDate,
      email: data?.user?.email,
      phone: data?.user?.phone,
      addresses: data?.user?.addresses ?? [],
    },
  });
  const { handleSubmit, reset } = form;

  const userID = data?.user?.ID!;

  const onSave = useCallback(async () => {
    await handleSubmit(async (data) => {
      await updateUser({
        variables: { input: { user: data, userID } },
        refetchQueries: [AccountSettingsQueryName, 'CurrentUser' satisfies CurrentUserQueryName],
      });
      navigation.setParams({ isEditing: 'false' });
    })();
  }, [updateUser, handleSubmit, navigation, userID]);

  useLayoutEffect(() => {
    if (isEditing) {
      navigation.setOptions({
        headerTitle: $t({ id: 'Account_editHeading', defaultMessage: 'Edit account settings' }),
        headerRight: () => (
          <HeaderButtons>
            <Button
              text={$t({ id: 'Account_saveButton', defaultMessage: 'Save' })}
              testID="Account_saveButton"
              alignSelf="flex-start"
              onPress={onSave}
              style={{ paddingHorizontal: 14 }}
            />
          </HeaderButtons>
        ),
        headerLeft: ({ tintColor }) => (
          <HeaderButtons>
            <HeaderItem
              testID="Account_cancelButton"
              accessibilityLabel={$t({
                id: 'Account_cancelButton',
                defaultMessage: 'Cancel',
              })}
              title=""
              onPress={() => {
                navigation.setParams({ isEditing: 'false' });
                reset();
              }}
              iconName="close"
              color={tintColor}
            />
          </HeaderButtons>
        ),
      });
    } else {
      navigation.setOptions({
        headerTitle: 'Account settings',
        headerLeft: undefined,
        headerRight: ({ tintColor }) => (
          <HeaderButtons>
            <HeaderItem
              accessibilityLabel={$t({ id: 'Account_editButton', defaultMessage: 'Edit' })}
              title=""
              onPress={() => navigation.setParams({ isEditing: 'true' })}
              iconName="edit"
              color={tintColor}
              testID="Account_editButton"
            />
          </HeaderButtons>
        ),
      });
    }
  }, [navigation, isEditing, onSave, $t, theme, reset]);

  return (
    <ScrollView testID="Account">
      <FormProvider {...form}>
        <View style={{ padding: 20 }} spacing={60}>
          <AccountSection />
          <ContactSection />
          {isEditing ? null : <NotificationSection />}
          <LegalSection />
          {isEditing ? null : (
            <Button
              style={{ marginTop: 40 }}
              text={$t({
                id: 'Account_logOutLink',
                defaultMessage: 'Log out',
              })}
              variant="text"
              onPress={logout}
            />
          )}
        </View>
      </FormProvider>
    </ScrollView>
  );
}
