'use client';

/* eslint-disable @typescript-eslint/no-explicit-any */

// apollo federation doesn't support subscriptions so we can't typegen from them
// but we need to import from source so that our babel plugin processes the alias properly
// This can be removed once we migrate all type gen to gqlcodegen
import { useMutation, useQuery, useSubscription, type ApolloError } from '@apollo/client';
import * as Sentry from '@sentry/core';
import equals from 'fast-deep-equal';
import { createContext, useCallback, useContext, useEffect, useMemo, useRef } from 'react';

import { graphql, VariablesOf } from '@oui/lib/src/graphql/tada';
import {
  CompositionSection,
  MyStoryMyPlanCompositionDataHash,
  MyStoryMyPlanCompositionSection,
  MyStoryMyPlanCompositionSectionHash,
} from '@oui/lib/src/myStoryMyPlanComposition';
import { ClinicalChecklist } from '@oui/lib/src/types/avro';
import { CompositionTemplate } from '@oui/lib/src/types/graphql.generated';
import { GQLDateTime, GQLUUID } from '@oui/lib/src/types/scalars';

import { useActiveRoute } from '../hooks/useActiveRoute';
import { useCurrentUser } from '../hooks/useCurrentUser';

export * from '@oui/lib/src/myStoryMyPlanComposition';

const CompositionByIDSubscription = graphql(`
  subscription CompositionByID($compositionID: UUID!) {
    compositionByID(compositionID: $compositionID) {
      __typename
      ID
      title
      sections {
        __typename
        ID
        title
        json
        createdAt
        updatedAt
      }
    }
  }
`);

const PatientPresenceSubscription = graphql(`
  subscription MyStoryMyPlanPatientPresence($patientID: UUID!) {
    patientPresence(patientID: $patientID) {
      timestamp
      connected
    }
  }
`);

const CompositionSectionFieldsFragment = graphql(`
  fragment CompositionSectionFields on CompositionSection @_unmask {
    __typename
    ID
    title
    json
    createdAt
    updatedAt
  }
`);

const CompositionFieldsFragment = graphql(
  `
    fragment CompositionFields on Composition @_unmask {
      __typename
      ID
      title
      sections {
        ...CompositionSectionFields
      }
    }
  `,
  [CompositionSectionFieldsFragment],
);

const CompositionByIDQuery = graphql(
  `
    query CompositionByID($compositionID: UUID!) {
      compositionByID(compositionID: $compositionID) {
        ...CompositionFields
      }
    }
  `,
  [CompositionFieldsFragment],
);

export const CompositionsQuery = graphql(
  `
    query Compositions($patientID: UUID, $title: String, $template: String) {
      compositions(patientID: $patientID, title: $title, template: $template) {
        ...CompositionFields
      }
    }
  `,
  [CompositionFieldsFragment],
);

export const NewCompositionWithTemplateMutation = graphql(
  `
    mutation NewCompositionWithTemplate(
      $patientID: UUID!
      $title: String
      $template: CompositionTemplate!
    ) {
      newCompositionWithTemplate(patientID: $patientID, title: $title, template: $template) {
        ...CompositionFields
      }
    }
  `,
  [CompositionFieldsFragment],
);

export const UpdateCompositionSectionMutation = graphql(
  `
    mutation UpdateCompositionSection($sectionID: UUID!, $title: String, $text: Any) {
      updateCompositionSection(sectionID: $sectionID, title: $title, text: $text) {
        ...CompositionSectionFields
      }
    }
  `,
  [CompositionSectionFieldsFragment],
);

export const SetMyStoryMyPlanCompleteMutation = graphql(`
  mutation SetMyStoryMyPlanComplete($patientID: UUID!) {
    setMyPlan: setOuiProgressForPatient(patientID: $patientID, content: MYPLAN, value: 1.0) {
      content
      completion
      updatedAt
    }
  }
`);

export function usePatientID(): GQLUUID | null {
  const { data: user } = useCurrentUser();
  const route = useActiveRoute();

  // TODO make compatible with SSR or update this file to take patientID as a param rather
  // than try to guess where it comes from globally
  if (process.env.NEXT_PUBLIC_API_CLIENT && typeof window !== 'undefined') {
    const url = new URL(window.location.href);
    const patientID = url.searchParams.get('patientID') as GQLUUID | null;
    if (patientID) return patientID;
  }

  if ((route.params as any)?.patientID) return (route.params as any)?.patientID;
  if (user?.currentUser?.role === 'PATIENT' && user?.currentUser?.user?.ID) {
    return user?.currentUser?.user?.ID;
  }
  return null;
}

export function useCompositionByID(variables: VariablesOf<typeof CompositionByIDQuery>) {
  return useQuery(CompositionByIDQuery, {
    variables,
  });
}

export function useCompositions(variables: VariablesOf<typeof CompositionsQuery>, skip?: boolean) {
  return useQuery(CompositionsQuery, {
    variables,
    skip,
  });
}

export function useComposition(variables: VariablesOf<typeof CompositionsQuery>) {
  const patientID = usePatientID();

  const { data, ...rest } = useCompositions(
    { ...variables, patientID: variables.patientID ?? patientID },
    !patientID,
  );

  // TODO figure out how to pick composition reliably w/o sort
  return {
    ...rest,
    data: data?.compositions
      ? [...data.compositions].sort((a, b) => (a.ID > b.ID ? -1 : 1))[0]
      : undefined,
    compositionsLength: data?.compositions.length ?? -1,
  };
}

export function useCreateComposition() {
  return useMutation(NewCompositionWithTemplateMutation);
}

export function useCompositionWithTitle({
  title,
  template,
  createIfUndefined,
}: {
  title: string;
  template: CompositionTemplate;
  createIfUndefined?: boolean;
}) {
  const patientID = usePatientID();
  const {
    refetch,
    loading: compositionLoading,
    data: composition,
    error,
    compositionsLength,
  } = useComposition({
    patientID,
    title,
  });
  const [createComposition] = useCreateComposition();

  useEffect(() => {
    if (error) {
      Sentry.withScope((scope) => {
        scope.setExtras({
          patientID,
          title,
          template,
        });
        Sentry.captureException(error);
      });
    }
  }, [patientID, title, template, error]);

  useEffect(() => {
    if (
      patientID &&
      createIfUndefined &&
      !compositionLoading &&
      typeof composition === 'undefined' &&
      compositionsLength === 0 &&
      !error
    ) {
      createComposition({
        variables: {
          patientID,
          title,
          template,
        },
      }).then(() => {
        refetch();
      });
    }
  }, [
    patientID,
    createIfUndefined,
    compositionLoading,
    composition,
    createComposition,
    template,
    title,
    error,
    refetch,
    compositionsLength,
  ]);

  return {
    error,
    loading: compositionLoading,
    data: composition,
    refetch,
  };
}

export function useCompositionSubscription(compositionID?: GQLUUID) {
  return useSubscription(CompositionByIDSubscription, {
    variables: compositionID ? { compositionID } : undefined,
    skip: !compositionID || !global.window, // Dont need subscription in SSR
  });
}

// Apollo subscriptions don't lookup data in the normalized cache like queries.
// That means each individual useSubscription hook will create a new subscription
// request to the server. Previously MyStoryMyPlan had a use*Subscription hook on
// each individual page of the flow. However, that resulted in subscription stop/start
// on each page transition which isn't ideal. To workaround, we load the subscription
// once at the root and then pass the subscription data through context
export const MyStoryMyPlanCompositionDataContext = createContext<
  MyStoryMyPlanCompositionDataHash | undefined | null
>(undefined);

export function useMyStoryMyPlanCompositionSubscription({
  createIfUndefined,
}: {
  createIfUndefined?: boolean;
}) {
  const { loading, error, data } = useMyStoryMyPlanComposition({ createIfUndefined });
  const result = useCompositionSubscription(data?.ID);
  return {
    // If data.ID is not present, we haven't yet started loading the subscription
    // because we don't know which composition to look for
    // If data.ID is present, we can use the loading state for the subscription since it should
    // either be inflight or resolved
    loading: data?.ID ? result.loading : loading,
    error: result.error || error,
    data: result.data,
  };
}

export function useMyStoryMyPlanCompositionSectionSubscription({
  createIfUndefined,
}: {
  createIfUndefined?: boolean;
} = {}): {
  error?: Error;
  loading: boolean;
  data: MyStoryMyPlanCompositionDataHash | undefined | null;
} {
  const { loading, data, error } = useMyStoryMyPlanCompositionSubscription({ createIfUndefined });

  const formattedData = useMemo(() => {
    return data
      ? (
          (data.compositionByID?.sections as ReadonlyArray<MyStoryMyPlanCompositionSection>) ?? []
        ).reduce<MyStoryMyPlanCompositionDataHash>((carry, section) => {
          if (section) {
            carry[section.title as any as keyof MyStoryMyPlanCompositionDataHash] =
              section.json as any;
          }
          return carry;
        }, {} as any)
      : undefined;
  }, [data]);

  return { loading, data: formattedData, error };
}

export function useMyStoryMyPlanCompositionSectionSubscriptionData() {
  return { data: useContext(MyStoryMyPlanCompositionDataContext) };
}

function useMyStoryMyPlanComposition({ createIfUndefined }: { createIfUndefined?: boolean }) {
  const { data, ...rest } = useCompositionWithTitle({
    title: 'MYSTORYMYPLAN',
    template: CompositionTemplate.MYSTORYMYPLAN,
    createIfUndefined,
  });
  const formattedData = useMemo(() => {
    return data
      ? {
          ...data,
          sections: getSectionHashFromComposition(data),
        }
      : undefined;
  }, [data]);

  return { ...rest, data: formattedData };
}

export function useUpdateCompositionSection() {
  return useMutation(UpdateCompositionSectionMutation);
}

function getSectionHashFromComposition(
  composition: NonNullable<ReturnType<typeof useCompositionWithTitle>['data']>,
): MyStoryMyPlanCompositionSectionHash {
  return (
    composition.sections as unknown as ReadonlyArray<MyStoryMyPlanCompositionSection>
  ).reduce<MyStoryMyPlanCompositionSectionHash>((carry, section) => {
    if (section) {
      carry[section.title as any as keyof MyStoryMyPlanCompositionSectionHash] = section as any;
    }
    return carry;
  }, {} as any);
}

function getDataHashFromSectionsHash(
  sections: NonNullable<ReturnType<typeof useMyStoryMyPlanComposition>['data']>['sections'],
): MyStoryMyPlanCompositionDataHash {
  return Object.entries(sections).reduce<MyStoryMyPlanCompositionDataHash>(
    (carry, [key, section]) => {
      if (section) {
        (carry as any)[key as any] = section.json;
      }
      return carry;
    },
    {} as any,
  );
}

export function getDataHashFromMyPlanComposition(
  composition: NonNullable<ReturnType<typeof useCompositionWithTitle>['data']>,
) {
  return getDataHashFromSectionsHash(getSectionHashFromComposition(composition));
}

export function useMyStoryMyPlanCompositionSections({
  createIfUndefined,
}: {
  createIfUndefined?: boolean;
} = {}): {
  loading: boolean;
  error?: ApolloError;
  data: MyStoryMyPlanCompositionDataHash | undefined;
  refetch: () => Promise<unknown>;
  update: (
    data:
      | Partial<MyStoryMyPlanCompositionDataHash>
      | ((data: Readonly<MyStoryMyPlanCompositionDataHash>) => MyStoryMyPlanCompositionDataHash),
  ) => Promise<unknown>;
} {
  const { loading, data, refetch, error } = useMyStoryMyPlanComposition({ createIfUndefined });
  const [update] = useUpdateCompositionSection();
  const dataRef = useRef(data);
  dataRef.current = data;
  const sectionsData = useMemo(() => {
    return data ? getDataHashFromSectionsHash(data.sections) : undefined;
  }, [data]);

  return {
    loading,
    refetch,
    error,
    data: sectionsData,
    update: useCallback(
      async (newDataOrCallback) => {
        if (dataRef.current?.sections) {
          const currentData = dataRef.current;
          const _sectionsData = getDataHashFromSectionsHash(currentData.sections);

          const newData =
            typeof newDataOrCallback === 'function'
              ? newDataOrCallback(_sectionsData)
              : newDataOrCallback;

          const promises: Promise<unknown>[] = Object.entries(newData)
            .map(([key, value]) => {
              const section =
                currentData.sections[key as keyof MyStoryMyPlanCompositionSectionHash]!;
              const isEqual = equals(value, _sectionsData[key as keyof typeof sectionsData]);
              return isEqual
                ? null!
                : update({ variables: { sectionID: section.ID, text: value } });
            })
            .filter((v) => !!v);

          await Promise.all(promises);
        }

        return Promise.resolve();
      },
      [update],
    ),
  };
}

export function useChecklist(title: string): {
  data: CompositionSection<ClinicalChecklist> | undefined;
  update: (data: ClinicalChecklist) => Promise<unknown>;
} {
  const { data } = useCompositionWithTitle({
    title,
    template: CompositionTemplate.MYSTORYMYPLAN_CLINICAL,
    createIfUndefined: true,
  });
  const [update] = useUpdateCompositionSection();
  const section = data?.sections?.[0];
  const sectionIDRef = useRef(section?.ID);
  sectionIDRef.current = section?.ID;

  return {
    data: section as any,
    update: useCallback(
      async (newData) => {
        if (sectionIDRef.current) {
          return update({ variables: { sectionID: sectionIDRef.current, text: newData } });
        }

        return Promise.resolve();
      },
      [update],
    ),
  };
}

export function usePatientPresence(patientID?: GQLUUID) {
  const { data } = useSubscription<
    {
      patientPresence: {
        timestamp: GQLDateTime;
        connected: boolean;
      };
    },
    {
      patientID: GQLUUID;
    }
  >(PatientPresenceSubscription, { variables: { patientID: patientID! }, skip: !patientID });
  return data;
}

export function useSetMyStoryMyPlanComplete() {
  return useMutation(SetMyStoryMyPlanCompleteMutation);
}
