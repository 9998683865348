import { useApolloClient } from '@apollo/client';
import { StackNavigationOptions } from '@react-navigation/stack';
import * as Sentry from '@sentry/core';
import { useEffect } from 'react';
import { Platform } from 'react-native';

import {
  ActivityDiary,
  ActivityPractice,
  EditActivityEvent,
  EditActivityPractice,
} from '@oui/activity-diary';
import { DEFAULT_HEADER_MODE, SESSION_TIMEOUT } from '@oui/app-core/src/constants';
import { useCurrentUser } from '@oui/app-core/src/hooks/useCurrentUser';
import { usePersistedState } from '@oui/app-core/src/hooks/usePersistedState';
import { createSessionTimeoutStackNavigator } from '@oui/app-core/src/lib/createSessionTimeoutNavigator';
import { useDefaultHeaderHeight } from '@oui/app-core/src/lib/getDefaultHeaderHeight';
import { useI18n } from '@oui/app-core/src/lib/i18n';
import { setDeviceInfo } from '@oui/app-core/src/lib/setDeviceInfo';
import Auth from '@oui/app-core/src/screens/Auth';
import { Conversation } from '@oui/app-core/src/screens/Conversation';
import { CreateTestUser } from '@oui/app-core/src/screens/CreateTestUser';
import { EditLessonsLearned } from '@oui/app-core/src/screens/EditLessonsLearned';
import LocalAuthenticationPrompt from '@oui/app-core/src/screens/LocalAuthenticationPrompt';
import { TermsAndPrivacy } from '@oui/app-core/src/screens/TermsAndPrivacy';
import { useTheme } from '@oui/app-core/src/styles';
import {
  BingeEatingMyPlan,
  EditDistractingActivities,
  EditMoodPlan,
  EditPreventingRelapse,
  EditSocialSupport,
} from '@oui/binge-eating-my-plan';
import {
  EatingLogEntry,
  EditEatingLogEntry,
  EditEatingSchedule,
  RecordProgress,
} from '@oui/eating-log';
import { AddHopeKit, HopeKit } from '@oui/hope-kit';
import { Relax } from '@oui/relax-diary';
import {
  EditSleepDiaryEntry,
  SetupSleepDiary,
  SleepDiary,
  SleepDiaryEntry,
} from '@oui/sleep-diary';

import { TabNavigator } from '@src/components/TabNavigator';
import { Account } from '@src/screens/Account';
import { Confidentiality } from '@src/screens/Confidentiality';
import { FinishRegistering } from '@src/screens/FinishRegistering';
import { GetStarted } from '@src/screens/GetStarted';
import { MyProgress } from '@src/screens/MyProgress';
import { Welcome } from '@src/screens/Welcome';
import { RootStackParamList } from '@src/types/navigation';

const Stack = createSessionTimeoutStackNavigator<RootStackParamList>(SESSION_TIMEOUT);
export const RootNavigator = () => {
  const { scheme, Color, theme } = useTheme();
  const { isLoggedIn } = useCurrentUser();
  const defaultHeaderHeight = useDefaultHeaderHeight();
  const { $t } = useI18n();
  const apollo = useApolloClient();

  const [hasSeenLocalAuthenticationPrompt] = usePersistedState(
    'SeenLocalAuthenticationPrompt',
    false,
  );

  useEffect(() => {
    if (isLoggedIn) {
      setDeviceInfo(apollo).catch(Sentry.captureException);
    }
  }, [apollo, isLoggedIn]);

  return (
    <Stack.Navigator
      {...Platform.select({ web: { cardStyle: { flex: 1 } } })} // So web screens grow properly
      screenOptions={() => {
        const options: StackNavigationOptions = {
          headerStyle: {
            height: defaultHeaderHeight,
          },
          headerBackTestID: 'header-back',
          headerTruncatedBackTitle: $t({ id: 'App_backButtonLabel', defaultMessage: 'Back' }),
          headerTintColor: theme.color.primary100,
          headerTitleStyle: { color: Color.heading, fontFamily: 'OpenSansSemiBold' },
          headerMode: DEFAULT_HEADER_MODE,
        };
        return options;
      }}
    >
      {isLoggedIn ? (
        <>
          {hasSeenLocalAuthenticationPrompt ? null : (
            <Stack.Screen
              name="LocalAuthenticationPrompt"
              component={LocalAuthenticationPrompt}
              options={{ headerShown: false }}
            />
          )}
          <Stack.Screen
            name="home"
            component={TabNavigator}
            options={{ headerShown: false, title: '' }}
          />
          <Stack.Screen name="TermsAndPrivacy" component={TermsAndPrivacy} />
          <Stack.Screen
            name="Confidentiality"
            component={Confidentiality}
            options={{ headerShown: false }}
          />
          <Stack.Screen
            name="FinishPatientRegistration"
            component={FinishRegistering}
            options={{ headerShown: false }}
          />
          <Stack.Screen name="GetStarted" component={GetStarted} options={{ headerShown: false }} />
          <Stack.Screen name="NewEatingLogEntry" component={EditEatingLogEntry} />
          <Stack.Screen name="EditEatingLogEntry" component={EditEatingLogEntry} />
          <Stack.Screen name="EatingLogEntry" component={EatingLogEntry} />
          <Stack.Screen
            name="EditEatingSchedule"
            component={EditEatingSchedule}
            options={{ title: 'Edit eating schedule' }}
          />

          <Stack.Screen name="Relaxation" component={Relax} />

          <Stack.Screen name="AddHopeKit" component={AddHopeKit} />
          <Stack.Screen name="HopeKit" component={HopeKit} />

          <Stack.Screen name="BingeEatingMyPlan" component={BingeEatingMyPlan} />
          <Stack.Screen name="EditDistractingActivities" component={EditDistractingActivities} />
          <Stack.Screen name="EditMoodPlan" component={EditMoodPlan} />
          <Stack.Screen name="EditPreventingRelapse" component={EditPreventingRelapse} />
          <Stack.Screen name="EditSocialSupport" component={EditSocialSupport} />

          <Stack.Screen name="ActivityDiary" component={ActivityDiary} />
          <Stack.Screen name="ActivityPractice" component={ActivityPractice} />
          <Stack.Screen name="EditActivityEvent" component={EditActivityEvent} />
          <Stack.Screen
            name="EditActivityPractice"
            component={EditActivityPractice}
            options={{
              title: $t({ id: 'EditActivityPractice_title', defaultMessage: 'Rate activity' }),
            }}
          />

          <Stack.Screen name="SleepDiary" component={SleepDiary} />
          <Stack.Screen
            name="SetupSleepDiary"
            component={SetupSleepDiary}
            options={{ title: 'Setup sleep diary' }}
          />
          <Stack.Screen name="EditSleepDiaryEntry" component={EditSleepDiaryEntry} />
          <Stack.Screen name="SleepDiaryEntry" component={SleepDiaryEntry} />

          <Stack.Screen name="Conversation" component={Conversation} />
          <Stack.Screen name="Account" component={Account} />
          <Stack.Screen name="RecordProgress" component={RecordProgress} />
          <Stack.Screen name="EditLessonsLearned" component={EditLessonsLearned} />
          <Stack.Screen name="MyProgress" component={MyProgress} />
        </>
      ) : (
        <>
          {Auth({ $t, Screen: Stack.Screen, theme: scheme, Color })}
          <Stack.Screen
            name="CreateTestUser"
            component={CreateTestUser}
            options={{ headerShown: false }}
          />
          <Stack.Screen name="Welcome" component={Welcome} options={{ headerShown: false }} />
        </>
      )}
    </Stack.Navigator>
  );
};
