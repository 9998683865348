import { Dimensions } from 'react-native';
import { Circle } from 'react-native-svg';

import { useTheme } from '../../styles';
import { LineChart as RNLineChart, XAxis, YAxis } from '../../svgCharts';
import { Text } from '../Text/Text';
import { View } from '../View';

export const LineChart = ({
  data,
  yAxis,
  xAxis,
  lineColor = '#5A79E8',
  title,
  width = Dimensions.get('window').width,
  height = 300,
}: {
  data: {
    date: string;
    value: number;
  }[];
  yAxis?: JSX.Element;
  xAxis?: JSX.Element;
  lineColor?: string;
  title?: string;
  width?: number;
  height?: number;
}) => {
  const { theme } = useTheme();

  if (!data || data.length === 0) {
    return (
      <View style={{ width, height, justifyContent: 'center', alignItems: 'center' }}>
        <Text text="No data available" />
      </View>
    );
  }

  const values = data.map((item) => item.value);

  const axesSvg = {
    fontSize: 13,
    fill: '#242226',
    fontFamily: 'OpenSansRegular',
    lineHeight: 17.7,
  };

  const Decorator = ({
    x,
    y,
    data,
  }: {
    x: (index: number) => number;
    y: (value: number) => number;
    data: number[];
  }) => {
    return (
      <>
        {data.map((value, index) => (
          <Circle
            key={index}
            cx={x(index)}
            cy={y(value)}
            r={4}
            stroke={lineColor}
            fill={lineColor}
          />
        ))}
      </>
    );
  };

  const defaultYAxis = (
    <YAxis
      style={{
        marginRight: 0,
        paddingRight: 7,
        paddingLeft: 7,
        marginBottom: 25,
        borderRightWidth: 1,
        borderColor: theme.color.gray600,
      }}
      data={values}
      contentInset={{ top: 7, bottom: -14 }}
      svg={axesSvg}
      numberOfTicks={5}
    />
  );

  const defaultXAxis = (
    <XAxis
      style={{
        borderTopWidth: 1,
        paddingTop: 7,
        borderColor: theme.color.gray600,
      }}
      data={data}
      xAccessor={({ item }) => new Date(item.date).getTime()}
      formatLabel={(value) => {
        const date = new Date(value);
        return `${date.getMonth() + 1}/${date.getDate()}`;
      }}
      contentInset={{ left: 16, right: 16 }}
      svg={axesSvg}
    />
  );

  return (
    <View
      style={{
        gap: 16,
      }}
    >
      {title && <Text weight="semibold" text={title} />}

      <View style={{ height, flexDirection: 'row' }}>
        {yAxis || defaultYAxis}
        <View style={{ flex: 1 }}>
          <RNLineChart
            style={{ flex: 1 }}
            data={values}
            contentInset={{ top: 16, bottom: 16, left: 16, right: 16 }}
            svg={{ stroke: lineColor, strokeWidth: 3 }}
          >
            <Decorator x={() => 0} y={() => 0} data={values} />
          </RNLineChart>
          {xAxis || defaultXAxis}
        </View>
      </View>
    </View>
  );
};
