// Taken from https://github.com/hyakt/expo-multiple-media-imagepicker

import addDays from 'date-fns/addDays';
import addMonths from 'date-fns/addMonths';
import parseISO from 'date-fns/parseISO';
import * as FileSystem from 'expo-file-system';
import * as MediaLibrary from 'expo-media-library';
import hexToRgba from 'hex-to-rgba';
import chunk from 'lodash/chunk';
import { Component, memo, ReactNode, useRef, useState } from 'react';
import { IntlShape } from 'react-intl';
import {
  Alert,
  BackHandler,
  Dimensions,
  FlatList,
  Image,
  ImageBackground,
  Modal,
  NativeEventSubscription,
  Platform,
  SafeAreaView as RNSafeAreaView,
  Text as RNText,
  SectionListProps,
  StatusBar,
  StyleSheet,
  TouchableHighlight,
  TouchableOpacity,
} from 'react-native';
import { SafeAreaView as SACSafeAreaView } from 'react-native-safe-area-context';

import { getGQLDate } from '@oui/lib/src/getGQLDate';
import { GQLDate } from '@oui/lib/src/types/scalars';

import { ActivityIndicator } from '../components/ActivityIndicator';
import { useAppContext } from '../components/AppContext';
import { Button } from '../components/Button';
import Divider from '../components/Divider';
import { Icon } from '../components/Icon';
import { Text } from '../components/Text';
import { View } from '../components/View';
import { IOS_14_OR_GREATER } from '../constants';
import { useI18n } from '../lib/i18n';
import Sentry from '../sentry';
import { useTheme } from '../styles';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const SafeAreaView = Platform.select({ android: SACSafeAreaView as any, default: RNSafeAreaView });
const { width } = Dimensions.get('window');
const MAX_FILE_SIZE = 100 * 1024 * 1024;

const ALL_MEDIA_ALBUM_ID = 'all-media';

function getAllMediaAlbum($t: IntlShape['$t']): MediaLibrary.Album {
  return {
    id: ALL_MEDIA_ALBUM_ID,
    title: $t({ id: 'MultipleMediaLibrary_allMediaAlbumTitle', defaultMessage: 'All media' }),
    assetCount: 100000000,
    endTime: Date.now(),
    startTime: Date.now(),
  };
}

export type DateFilter = { startDate: string | null; endDate: string | null };
function getMonthsForLocale(locale: string) {
  const format = new Intl.DateTimeFormat(locale, { month: 'short' });
  const months = [];
  for (let month = 1; month < 13; month++) {
    const testDate = new Date(Date.UTC(2000, month, 1, 0, 0, 0));
    months.push(format.format(testDate));
  }
  return months;
}

function formatDateRange(locale: string, dateFilter: DateFilter) {
  if (!dateFilter.startDate || !dateFilter.endDate) return { label: '', accessibilityLabel: '' };
  const formatter = Intl.DateTimeFormat(locale, {
    year: 'numeric',
    month: 'numeric',
  });
  const accessibilityLabelFormat = Intl.DateTimeFormat(locale, {
    year: 'numeric',
    month: 'long',
  });
  return {
    label: `${formatter.format(parseISO(dateFilter.startDate))} - ${formatter.format(
      parseISO(dateFilter.endDate),
    )}`,
    accessibilityLabel: `from ${accessibilityLabelFormat.format(
      parseISO(dateFilter.startDate),
    )} to ${accessibilityLabelFormat.format(parseISO(dateFilter.endDate))}`,
  };
}

function DateRangeInputModal(props: {
  value: DateFilter;
  onChangeValue: (data: DateFilter) => void;
}) {
  const { locale } = useAppContext();
  const { formatDate } = useI18n();
  const now = useRef(new Date());
  const { Color } = useTheme();
  const [startDate, setStartDate] = useState<string | null>(props.value.startDate);
  const [endDate, setEndDate] = useState<string | null>(props.value.endDate);
  const [currentYear, setCurrentYear] = useState(() => {
    if (startDate) return parseISO(startDate).getFullYear();
    return now.current.getFullYear();
  });
  const months = getMonthsForLocale(locale);
  const accessibilityLabelFormat = {
    format: (date: Date) => {
      return formatDate(date, {
        month: 'short',
        year: 'numeric',
      });
    },
  };

  function prevYear() {
    setCurrentYear((y) => y - 1);
  }
  function nextYear() {
    setCurrentYear((y) => y + 1);
  }
  function setActiveDate(date: GQLDate) {
    const bothSet = (startDate && endDate) || (startDate && date < startDate);
    if (startDate === null || bothSet) {
      setStartDate(date);
      if (bothSet) {
        setEndDate(null);
      }
    } else {
      setEndDate(date);
    }
  }

  return (
    <Modal visible={true} onRequestClose={() => {}} transparent>
      <TouchableOpacity
        activeOpacity={1}
        style={{
          flex: 1,
          justifyContent: 'center',
          alignItems: 'center',
          backgroundColor: hexToRgba(Color.styleGuide.Gray1, '0.5'),
        }}
        onPress={() => {}}
        accessibilityRole="none"
      >
        <StatusBar
          backgroundColor={hexToRgba(Color.styleGuide.Gray1, '0.5')}
          barStyle="light-content"
          translucent
        />
        <TouchableOpacity
          activeOpacity={1}
          style={{
            width: '90%',
            maxWidth: 400,
            backgroundColor: Color.backgroundColor,
            marginHorizontal: 10,
            borderRadius: 20,
            paddingVertical: 26,
            paddingHorizontal: 12,
          }}
          accessibilityLabel="Date range input"
          accessibilityHint="Choose the start and end month to filter to the selected range of dates"
        >
          <View row style={{ justifyContent: 'space-between' }}>
            <Button
              variant="text"
              icon="caret-left"
              onPress={prevYear}
              accessibilityLabel="Previous year"
            />
            <Text text={currentYear.toString()} size={21} />
            <Button
              accessibilityLabel="Next year"
              variant="text"
              icon="caret-right"
              onPress={nextYear}
              disabled={now.current.getFullYear() <= currentYear}
            />
          </View>
          <View
            style={{
              flexDirection: 'row',
              flexWrap: 'wrap',
              paddingTop: 15,
              marginHorizontal: -12,
            }}
          >
            {months.map((month, i) => {
              const date = new Date(Date.UTC(currentYear, i + 1, 1, 0, 0, 0));
              const monthDate = getGQLDate(date);
              const accessibilityLabel = accessibilityLabelFormat.format(date);
              const isHighlighted =
                startDate && endDate && startDate <= monthDate && monthDate <= endDate;
              const isStart = startDate === monthDate;
              const isEnd = endDate === monthDate;
              return (
                <View
                  key={i}
                  style={[
                    {
                      flexBasis: [1, 4, 7, 10].includes(i) ? '33.5%' : '33%',
                      alignItems: 'center',
                      marginBottom: 6,
                    },
                  ]}
                >
                  <View
                    style={[
                      { position: 'absolute', top: 0, left: 0, bottom: 0, right: '50%' },
                      isHighlighted && !isStart
                        ? { backgroundColor: hexToRgba(Color.accent, 0.3) }
                        : null,
                    ]}
                  />
                  <View
                    style={[
                      { position: 'absolute', top: 0, left: '50%', bottom: 0, right: 0 },
                      isHighlighted && !isEnd
                        ? { backgroundColor: hexToRgba(Color.accent, 0.3) }
                        : null,
                    ]}
                  />
                  <TouchableOpacity
                    activeOpacity={1}
                    onPress={() => setActiveDate(monthDate)}
                    style={[
                      { width: 66, padding: 10, alignItems: 'center' },
                      isStart || isEnd ? { borderRadius: 20, backgroundColor: Color.accent } : null,
                    ]}
                    accessibilityLabel={accessibilityLabel}
                    accessibilityHint={
                      startDate === null || (startDate && endDate)
                        ? 'Choose the beginning of the date range'
                        : 'Choose the end of the date range'
                    }
                  >
                    <Text weight="bold" color={isStart || isEnd ? 'white' : 'black'} text={month} />
                  </TouchableOpacity>
                </View>
              );
            })}
          </View>
          <View row style={{ justifyContent: 'space-around', marginTop: 32 }}>
            <Button
              onPress={() => {
                props.onChangeValue({ startDate: null, endDate: null });
              }}
              text="Clear"
              variant="text"
            />
            <Button
              onPress={() => {
                if (startDate && endDate) {
                  props.onChangeValue({ startDate, endDate });
                }
              }}
              text="Select"
              variant="solid"
              disabled={!startDate || !endDate}
            />
          </View>
        </TouchableOpacity>
      </TouchableOpacity>
    </Modal>
  );
}

export const ImageTile = memo(function ImageTile({
  item,
  index,
  selected,
  selectImage,
  selectedItemCount,
  badgeColor,
}: {
  item: MediaLibrary.Asset;
  index: number;
  selected: boolean;
  selectImage: (i: number) => Promise<{ isAllowed: boolean }>;
  selectedItemCount: number;
  badgeColor: string;
}) {
  const [isSelecting, setIsSelecting] = useState(false);
  const [isUnavailable, setIsUnavailable] = useState(false);
  if (!item) return null;
  return (
    <TouchableHighlight
      testID={`ImageTile_${index}`}
      style={{ opacity: selected ? 0.8 : 1 }}
      underlayColor="transparent"
      onPress={async () => {
        setIsSelecting(true);
        const { isAllowed } = await selectImage(index);
        if (!isAllowed) {
          setIsUnavailable(true);
        }
        setIsSelecting(false);
      }}
      accessibilityLabel={`result ${index + 1}`}
      accessibilityRole="imagebutton"
      accessibilityState={{
        selected,
      }}
    >
      <View style={{ position: 'relative' }}>
        <View style={{ flex: 1, alignItems: 'center', justifyContent: 'center' }}>
          <ImageBackground
            style={{ width: width / 4, height: width / 4 }}
            source={{ uri: item.uri }}
            resizeMethod="resize"
          >
            {item.mediaType === MediaLibrary.MediaType.video ? (
              <View style={{ width: '100%', height: '100%', justifyContent: 'center' }} row>
                <View
                  style={{
                    width: 50,
                    height: 50,
                    borderRadius: 25,
                    backgroundColor: 'rgba(255,255,255,0.3)',
                    justifyContent: 'center',
                  }}
                  row
                >
                  <Icon name="play" />
                </View>
              </View>
            ) : null}
            {selected && (
              <View style={[styles.countBadge, { backgroundColor: badgeColor }]}>
                <RNText style={styles.countBadgeText}>{selectedItemCount}</RNText>
              </View>
            )}
          </ImageBackground>
        </View>
        {isSelecting ? (
          <View
            style={[
              {
                position: 'absolute',
                right: 5,
                bottom: 5,
                backgroundColor: 'rgba(0,0,0,0.3)',
                padding: 5,
                borderRadius: 20,
              },
            ]}
          >
            <ActivityIndicator />
          </View>
        ) : isUnavailable ? (
          <View>
            <Icon name="warning" />
          </View>
        ) : null}
      </View>
    </TouchableHighlight>
  );
});

export class MultipleMediaLibrary extends Component<
  {
    $t: IntlShape['$t'];
    badgeColor: string;
    callback: (assets: MediaLibrary.AssetInfo[]) => void;
    emptyText?: string;
    headerButtonColor?: string;
    headerSelectText?: string;
    loadingColor?: string;
    max: number;
    mediaSubtype?: MediaLibrary.MediaSubtype;
    render?: (
      renderData: {
        albums: MediaLibrary.Album[];
        selectedAlbumId: string | null;
        onSelectAlbumId: (albumId: string) => void;
        showDatePicker: () => void;
        dateFilterString: string;
        dateFilterAccessibilityLabel: string;
        permissionGranted: boolean;
      } & Partial<SectionListProps<MediaLibrary.Asset[]>>,
    ) => ReactNode;
    immediateCallback?: boolean;
    defaultToAllMediaAlbum?: boolean;
  },
  {
    albums: MediaLibrary.Album[];
    previewImageByAlbumId: Record<string, MediaLibrary.Asset>;
    photos: MediaLibrary.Asset[];
    selectedAlbumId: string | null;
    selected: string[];
    after: string | null;
    hasNextPage: boolean;
    isLoading: boolean;
    isShowingDatePicker: boolean;
    dateFilter: DateFilter;
    permissionGranted: boolean;
  }
> {
  assetInfosById: Record<string, Promise<MediaLibrary.AssetInfo>> = {};
  backListener?: NativeEventSubscription;

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  constructor(props: any) {
    super(props);
    this.state = {
      previewImageByAlbumId: {},
      selectedAlbumId: this.props.defaultToAllMediaAlbum ? ALL_MEDIA_ALBUM_ID : null,
      isLoading: false,
      photos: [],
      albums: [],
      selected: [],
      after: null,
      hasNextPage: true,
      isShowingDatePicker: false,
      dateFilter: { startDate: null, endDate: null },
      permissionGranted: true,
    };
  }

  componentDidMount() {
    MediaLibrary.getPermissionsAsync().then((permissions) => {
      this.setState({ permissionGranted: permissions.granted });
      if (permissions.granted) {
        const accessPrivileges =
          Platform.OS === 'ios' && IOS_14_OR_GREATER
            ? permissions.accessPrivileges
            : permissions.granted
              ? 'all'
              : 'none';
        if (accessPrivileges === 'all') {
          this.getAlbums().then(() => {
            if (this.state.selectedAlbumId) {
              this.getAndProcessPhotos({ albumId: this.state.selectedAlbumId });
            }
          });
        } else {
          this.getAndProcessPhotos({});
        }
      }
    });
    if (!this.props.immediateCallback) {
      this.backListener = BackHandler.addEventListener('hardwareBackPress', this.backHandler);
    }
  }

  componentWillUnmount() {
    if (!this.props.immediateCallback) {
      this.backListener?.remove();
    }
  }

  backHandler = () => {
    if (this.state.selectedAlbumId) {
      this.setState({ selectedAlbumId: null });
      return true;
    }
    return false;
  };

  selectImage = async (index: number): Promise<{ isAllowed: boolean }> => {
    const asset = this.state.photos[index];
    const assetInfoPromise = this.assetInfosById[asset.id] ?? MediaLibrary.getAssetInfoAsync(asset);
    this.assetInfosById[asset.id] = assetInfoPromise;
    const assetInfo = await assetInfoPromise;
    const fileInfo = await FileSystem.getInfoAsync(assetInfo.localUri ?? assetInfo.uri);

    const validSize = fileInfo.exists && fileInfo.size && fileInfo.size < MAX_FILE_SIZE;

    if (validSize) {
      let newSelected = Array.from(this.state.selected);

      if (newSelected.indexOf(asset.id) === -1) {
        newSelected.push(asset.id);
      } else {
        const deleteIndex = newSelected.indexOf(asset.id);
        newSelected.splice(deleteIndex, 1);
      }

      if (newSelected.length > this.props.max) {
        Alert.alert("You've reached the maximum number of allowed selections.");
        return { isAllowed: false };
      }
      if (newSelected.length === 0) newSelected = [];

      this.setState({ selected: newSelected });
      if (this.props.immediateCallback) this.props.callback([assetInfo]);
      return { isAllowed: true };
    }

    Alert.alert('The selected media is too large. Please choose a different file.');
    return { isAllowed: false };
  };

  getAlbums = async () => {
    this.setState({ isLoading: true });
    let albums = [
      getAllMediaAlbum(this.props.$t),
      ...(await MediaLibrary.getAlbumsAsync()).filter(
        // we generally hide folders with a single asset, but we rely on being able to select DCIM
        // during e2e tests so we add a special case
        (a) => a.assetCount > 1 || (a.assetCount === 1 && a.title === 'DCIM'),
      ),
    ];

    const previewImageByAlbumId: Record<string, MediaLibrary.Asset> = {};
    const previewAssetsPromises = albums.map(async (album) => {
      try {
        const result = await this.getPhotos({ limit: 1, albumId: album.id });
        if (result) {
          const asset = result.assets[0];
          if (asset) {
            previewImageByAlbumId[album.id] = asset;
          } else {
            albums = albums.filter((a) => a.id !== album.id);
          }
        }
      } catch (e) {
        Sentry.withScope((scope) => {
          scope.setExtras({ album });
          Sentry.captureException(e);
        });
        albums = albums.filter((a) => a.id !== album.id);
      }
    });
    await Promise.all(previewAssetsPromises);

    albums = albums.sort((a, b) => {
      const assetA = previewImageByAlbumId[a.id];
      const assetB = previewImageByAlbumId[b.id];
      if (assetA.modificationTime === assetB.modificationTime) {
        return a.id > b.id ? -1 : 1;
      }
      return assetA.modificationTime > assetB.modificationTime ? -1 : 1;
    });

    return new Promise<void>((resolve) => {
      this.setState({ albums, previewImageByAlbumId, isLoading: false }, resolve);
    });
  };

  getPhotos = (options: { limit?: number; albumId?: string } = {}) => {
    let params: MediaLibrary.AssetsOptions = {
      album: options.albumId === ALL_MEDIA_ALBUM_ID ? undefined : options.albumId,
      first: options.limit ?? 40,
      mediaType: [MediaLibrary.MediaType.video, MediaLibrary.MediaType.photo],
      sortBy: [MediaLibrary.SortBy.modificationTime],
    };
    if (this.state.dateFilter.startDate) {
      params.createdAfter = addDays(parseISO(this.state.dateFilter.startDate), -1);
    }
    if (this.state.dateFilter.endDate) {
      params.createdBefore = addMonths(parseISO(this.state.dateFilter.endDate), 1);
    }
    if (this.state.after) params.after = this.state.after;
    if (!this.state.hasNextPage) return;
    this.setState({ isLoading: true });
    return MediaLibrary.getAssetsAsync(params);
  };

  getAndProcessPhotos = (options: { limit?: number; albumId?: string }) => {
    if (this.state.permissionGranted) {
      this.getPhotos(options)?.then((assets) => {
        this.processPhotos(assets);
      });
    }
  };

  processPhotos = async (assets: MediaLibrary.PagedInfo<MediaLibrary.Asset>) => {
    if (this.state.after === assets.endCursor) return;

    let displayAssets;
    if (!this.props.mediaSubtype) {
      displayAssets = assets.assets;
    } else {
      displayAssets = assets.assets.filter((asset) => {
        return asset.mediaSubtypes?.includes(this.props.mediaSubtype!);
      });
    }

    const originalLength = displayAssets.length;

    Sentry.addBreadcrumb({
      type: 'media-library',
      message: 'processPhotos',
      data: {
        originalLength,
        currentLength: this.state.photos.length,
        appendLength: displayAssets.length,
        sample: displayAssets[0],
      },
    });

    this.setState({
      photos: [...this.state.photos, ...displayAssets],
      after: assets.endCursor,
      hasNextPage: assets.hasNextPage,
      isLoading: false,
    });
  };

  getItemLayout = (data: unknown, index: number) => {
    let length = width / 4;
    return { length, offset: length * index, index };
  };

  prepareCallback = async () => {
    let { selected } = this.state;
    const selectedPhotos = selected.map((id) => {
      return this.assetInfosById[id];
    });
    this.props.callback(await Promise.all(selectedPhotos));
  };

  renderHeader() {
    let selectedCount = this.state.selected.length;

    let headerText = `${selectedCount} ${
      this.props.headerSelectText ? this.props.headerSelectText : 'Selected'
    }`;
    if (selectedCount === this.props.max) headerText = headerText + ' (Max)';
    const headerButtonColor = this.props.headerButtonColor
      ? this.props.headerButtonColor
      : '#007aff';

    return (
      <SafeAreaView>
        <View style={styles.header}>
          <View style={{ width: 100 }}>
            {this.state.selectedAlbumId ? (
              <Button
                accessibilityLabel="Back to album list"
                color={headerButtonColor}
                icon="arrow-left"
                onPress={() => {
                  this.setState({ selectedAlbumId: null, after: null, hasNextPage: true });
                }}
                variant="text"
              />
            ) : (
              <Button
                accessibilityLabel="Cancel and go back"
                color={headerButtonColor}
                icon="close"
                onPress={() => this.props.callback([])}
                variant="text"
              />
            )}
          </View>
          <RNText style={styles.headerText}>{headerText}</RNText>
          <View style={{ width: 100 }}>
            <Button
              color={headerButtonColor}
              text="Done"
              onPress={() => this.prepareCallback()}
              alignSelf="flex-end"
              style={{ paddingHorizontal: 10 }}
            />
          </View>
        </View>
      </SafeAreaView>
    );
  }

  renderImageTile = ({ item, index }: { item?: MediaLibrary.Asset; index: number }) => {
    if (!item) return null;
    const selected = this.state.selected.indexOf(item.id) !== -1;
    const selectedItemCount = this.state.selected.indexOf(item.id) + 1;

    return (
      <ImageTile
        key={item.id}
        item={item}
        selectedItemCount={selectedItemCount}
        index={index}
        selected={selected}
        selectImage={this.selectImage}
        badgeColor={this.props.badgeColor}
      />
    );
  };

  renderImageTileRow = ({ item: row, index }: { item: MediaLibrary.Asset[]; index: number }) => {
    return (
      <View row style={{ backgroundColor: 'white' }}>
        {row.map((item, i) => this.renderImageTile({ item, index: index * 4 + i }))}
      </View>
    );
  };

  renderLoading() {
    return (
      <View style={styles.emptyContent}>
        <ActivityIndicator
          size="large"
          color={this.props.loadingColor ? this.props.loadingColor : '#bbb'}
        />
      </View>
    );
  }

  renderEmpty = () => {
    return this.state.isLoading ? (
      this.renderLoading()
    ) : (
      <View style={styles.emptyContent}>
        <RNText style={styles.emptyText}>
          {this.props.emptyText ? this.props.emptyText : 'No media available'}
        </RNText>
      </View>
    );
  };

  renderAlbums() {
    return (
      <FlatList<MediaLibrary.Album>
        key="albums"
        contentContainerStyle={{ flexGrow: 1 }}
        data={this.state.albums}
        renderItem={({ item }) => (
          <TouchableOpacity
            onPress={() => {
              this.setState({ photos: [], selectedAlbumId: item.id }, () => {
                this.getAndProcessPhotos({ albumId: item.id });
              });
            }}
          >
            <View row spacing={10}>
              <Image
                style={[
                  {
                    borderWidth: 1,
                    borderColor: '#eee',
                    margin: 10,
                    borderRadius: 10,
                    width: width / 4,
                    height: width / 4,
                  },
                ]}
                source={{ uri: this.state.previewImageByAlbumId[item.id].uri }}
                resizeMethod="resize"
              />
              <View flex={1}>
                <Text text={item.title} weight="bold" />
                {item.id === ALL_MEDIA_ALBUM_ID ? null : (
                  <Text text={item.assetCount.toString()} size={13} />
                )}
              </View>
              <Icon name="caret-right" style={{ marginHorizontal: 10 }} />
            </View>
            <Divider style={{ marginVertical: 0 }} />
          </TouchableOpacity>
        )}
        keyExtractor={({ id }) => id}
        ListEmptyComponent={this.renderEmpty}
      />
    );
  }

  renderImages() {
    return (
      <FlatList
        key="images"
        contentContainerStyle={{ flexGrow: 1 }}
        data={this.state.photos}
        numColumns={4}
        renderItem={this.renderImageTile}
        keyExtractor={(_, index) => index.toString()}
        onEndReached={() => {
          this.getAndProcessPhotos({ albumId: this.state.selectedAlbumId ?? undefined });
        }}
        onEndReachedThreshold={0.5}
        ListEmptyComponent={this.renderEmpty}
        initialNumToRender={40}
        getItemLayout={this.getItemLayout}
      />
    );
  }

  render() {
    if (this.props.render) {
      return (
        <>
          {this.props.render({
            permissionGranted: this.state.permissionGranted,
            sections: [
              {
                title: '',
                data: chunk(this.state.photos, 4),
              },
            ],
            renderItem: this.renderImageTileRow,
            keyExtractor: (row) => row.map((item) => item.id).join('-'),
            onEndReached: () =>
              this.getAndProcessPhotos({ albumId: this.state.selectedAlbumId ?? undefined }),
            onEndReachedThreshold: 0.5,
            ListEmptyComponent: this.renderEmpty,
            initialNumToRender: 24,
            getItemLayout: this.getItemLayout,
            albums: this.state.albums,
            selectedAlbumId: this.state.selectedAlbumId,
            onSelectAlbumId: (albumId: string) => {
              if (this.state.selectedAlbumId === albumId) return;
              this.setState(
                { photos: [], after: null, hasNextPage: true, selectedAlbumId: albumId },
                () => {
                  this.getAndProcessPhotos({ albumId });
                },
              );
            },
            showDatePicker: () => this.setState({ isShowingDatePicker: true }),
            dateFilterString: formatDateRange('en', this.state.dateFilter).label,
            dateFilterAccessibilityLabel: formatDateRange('en', this.state.dateFilter)
              .accessibilityLabel,
          })}
          {this.state.isShowingDatePicker ? (
            <DateRangeInputModal
              value={this.state.dateFilter}
              onChangeValue={(value) => {
                this.setState(
                  {
                    dateFilter: value,
                    photos: [],
                    after: null,
                    hasNextPage: true,
                    isShowingDatePicker: false,
                  },
                  () => {
                    this.getAndProcessPhotos({ albumId: this.state.selectedAlbumId ?? undefined });
                  },
                );
              }}
            />
          ) : null}
        </>
      );
    }

    return (
      <View style={styles.container}>
        {this.renderHeader()}
        <Divider style={{ marginVertical: 0 }} />
        {this.state.selectedAlbumId ? this.renderImages() : this.renderAlbums()}
      </View>
    );
  }
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
  },
  header: {
    width: width,
    justifyContent: 'space-between',
    flexDirection: 'row',
    alignItems: 'center',
    padding: 10,
  },
  headerText: {
    fontWeight: 'bold',
    fontSize: 16,
    lineHeight: 19,
  },
  emptyContent: {
    flexGrow: 1,
    justifyContent: 'center',
    alignItems: 'center',
  },
  emptyText: {
    color: '#bbb',
    fontSize: 20,
  },
  countBadge: {
    minWidth: 20,
    minHeight: 20,
    borderRadius: 50,
    position: 'absolute',
    right: 3,
    bottom: 3,
    justifyContent: 'center',
  },
  countBadgeText: {
    color: '#fff',
    fontWeight: 'bold',
    alignSelf: 'center',
    padding: 'auto',
  },
});
