import isEqual from 'lodash/isEqual';
import { ComponentProps, ReactNode } from 'react';
import { StyleProp, ViewStyle } from 'react-native';

import { Checkbox } from '../components/Checkbox';
import { View } from '../components/View';
import { AccessibleInput } from '../hooks/useAccessibleInput';
import { useTheme } from '../styles';

type Props<T> = {
  required?: boolean;
  disabled?: boolean;
  error?: string;
  horizontal?: boolean;
  items: {
    label: string | ReactNode;
    accessibilityLabel?: string;
    value: T;
    key?: string;
    selectedChild?: ReactNode;
    unselectedChild?: ReactNode;
    selectedStyle?: StyleProp<ViewStyle>;
    style?: StyleProp<ViewStyle>;
  }[];
  onChangeValue?: (value: T) => void;
  style?: StyleProp<ViewStyle>;
  innerStyle?: StyleProp<ViewStyle>;
  testID?: string;
  value?: T | null;
  labelWeight?: ComponentProps<typeof Checkbox>['labelWeight'];
  optionLabelWeight?: ComponentProps<typeof Checkbox>['labelWeight'];
  spacing?: number;
  labelSize?: 'large' | 'normal';
  labelSpacing?: number;
  variant?: 'checkbox' | 'radio';
} & (
  | {
      label: string;
      accessibilityLabel?: string;
    }
  | {
      label?: () => ReactNode;
      accessibilityLabel: string;
    }
);

function RadioInput<T>({
  disabled,
  horizontal,
  items,
  onChangeValue,
  value,
  innerStyle,
  labelSize,
  labelSpacing,
  variant = 'radio',
  ...props
}: Props<T>) {
  const { theme } = useTheme();

  return (
    <AccessibleInput
      {...props}
      labelSize={labelSize === 'large' ? 21 : undefined}
      labelLineHeight={labelSize === 'large' ? 28 : undefined}
    >
      {(accessibleProps) => (
        <View
          {...accessibleProps}
          accessibilityRole="radiogroup"
          spacing={props.spacing ?? theme.checkbox.optionSpacing}
          row={horizontal}
          style={[
            { marginLeft: 16 },
            horizontal ? { minHeight: 50 } : null,
            innerStyle,
            labelSize === 'large' ? { marginTop: (labelSpacing ?? 20) - 5 } : null,
          ]}
          testID={props.testID}
        >
          {items.map((item, i) => {
            const isSelected = Array.isArray(value)
              ? value.includes(item.value)
              : isEqual(value, item.value);

            return (
              <View
                key={
                  item.key ||
                  (['string', 'boolean', 'number'].includes(typeof item.value)
                    ? (item.value as string).toString()
                    : typeof item.label === 'string'
                      ? item.label
                      : i.toString())
                }
                style={isSelected ? [item.style, item.selectedStyle] : item.style}
                spacing={10}
              >
                <Checkbox
                  disabled={disabled}
                  value={isSelected}
                  onChangeValue={() => {
                    if (Array.isArray(value)) {
                      if (isSelected) {
                        onChangeValue?.(value.filter((v) => v !== item.value) as T);
                      } else {
                        onChangeValue?.([...value, item.value] as T);
                      }
                    } else {
                      onChangeValue?.(item.value);
                    }
                  }}
                  variant={variant ?? 'radio'}
                  label={item.label}
                  accessibilityLabel={item.accessibilityLabel}
                  labelWeight={props.optionLabelWeight ?? props.labelWeight}
                  horizontal
                  testID={props.testID ? `${props.testID}_choice_${i}` : undefined}
                />
                {isSelected ? item.selectedChild : item.unselectedChild}
              </View>
            );
          })}
        </View>
      )}
    </AccessibleInput>
  );
}

export { RadioInput };
/**
 * @deprecated Prefer named export
 */
export default RadioInput;
