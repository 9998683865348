import { useState } from 'react';

import { ReauthenticationModal } from '../components/Reauthenticate';
import { SetPINModal } from '../components/SetPINModal';
import { SwitchInput } from '../components/SwitchInput';
import { Text } from '../components/Text';
import { View } from '../components/View';
import {
  AuthenticationType,
  AuthenticationTypes,
  useReauthenticationState,
} from '../hooks/useReauthenticationState';
import { useI18n } from '../lib/i18n';

export function AuthenticationSettings() {
  const { $t } = useI18n();
  const [enrollingType, setEnrollingType] = useState<AuthenticationType>();
  const [showSetPINModal, setShowSetPINModal] = useState(false);
  const { addEnrollment, removeEnrollment, enrolledTypes, supportedTypes } =
    useReauthenticationState();

  function AuthStrategyUI(props: { type: AuthenticationType }) {
    function getEnableButtonText() {
      switch (props.type) {
        case AuthenticationTypes.BIOMETRIC:
          return $t({
            id: 'AccountSettings_authentication_biometricLabel',
            defaultMessage: 'Fingerprint or Face unlock',
          });
        case AuthenticationTypes.FINGERPRINT:
          return $t({
            id: 'AccountSettings_authentication_fingerprintLabel',
            defaultMessage: 'Fingerprint unlock',
          });
        case AuthenticationTypes.FACIAL_RECOGNITION:
          return $t({
            id: 'AccountSettings_authentication_faceLabel',
            defaultMessage: 'Face unlock',
          });
        case AuthenticationTypes.PIN:
          return $t({
            id: 'AccountSettings_authentication_pinLabel',
            defaultMessage: 'PIN unlock',
          });
        case AuthenticationTypes.IRIS:
          return $t({
            id: 'AccountSettings_authentication_irisLabel',
            defaultMessage: 'Iris unlock',
          });
      }
    }

    return (
      <View row style={{ justifyContent: 'space-between' }}>
        <Text text={getEnableButtonText()} weight="semibold" accessibilityRole="none" />
        <SwitchInput
          accessibilityLabel={getEnableButtonText()}
          showOnOff
          value={!!enrolledTypes.includes(props.type)}
          onChangeValue={(newValue) => {
            if (newValue) {
              setEnrollingType(props.type);
            } else {
              removeEnrollment(props.type);
            }
          }}
        />
      </View>
    );
  }

  return (
    <View spacing={12}>
      {supportedTypes.map((type) => (
        <AuthStrategyUI type={type} key={type} />
      ))}
      {showSetPINModal ? (
        <SetPINModal
          onRequestClose={() => {
            setShowSetPINModal(false);
            setEnrollingType(undefined);
          }}
          onSetPIN={() => {
            addEnrollment(AuthenticationTypes.PIN);
            setShowSetPINModal(false);
            setEnrollingType(undefined);
          }}
        />
      ) : typeof enrollingType === 'number' ? (
        <ReauthenticationModal
          onSuccess={() => {
            if (enrollingType === (AuthenticationTypes.PIN as AuthenticationType)) {
              setShowSetPINModal(true);
              return;
            }
            addEnrollment(enrollingType);
            setEnrollingType(undefined);
          }}
          onRequestClose={() => setEnrollingType(undefined)}
        />
      ) : null}
    </View>
  );
}
