import { ComponentProps } from 'react';

import { Icon } from '../components/Icon';
import { Text } from '../components/Text';
import { View } from '../components/View';

export function Pill({
  color = '#69826F',
  backgroundColor = 'white',
  icon,
  text,
}: {
  color?: string;
  backgroundColor?: string;
  icon?: ComponentProps<typeof Icon>['name'];
  text: string;
}) {
  return (
    <View
      style={{
        backgroundColor,
        borderRadius: 12,
        paddingHorizontal: 12,
        paddingVertical: 2,
        justifyContent: 'center',
      }}
      spacing={12}
      row
    >
      {icon ? <Icon size={14} name={icon} color={color} /> : null}
      <Text text={text} size={13} color={color} weight="semibold" />
    </View>
  );
}
