import { ReactNode } from 'react';

import { ActivityDiaryProvider } from '@oui/activity-diary';
import { useTheme } from '@oui/app-core/src/styles';
import { BingeEatingMyPlanProvider } from '@oui/binge-eating-my-plan';
import { EatingLogProvider } from '@oui/eating-log';
import { HopeKitProvider } from '@oui/hope-kit';
import { RelaxProvider } from '@oui/relax-diary';
import { SleepDiaryProvider } from '@oui/sleep-diary';

export function WidgetProviders({ children }: { children?: ReactNode }) {
  const { theme } = useTheme();
  return (
    <BingeEatingMyPlanProvider>
      <RelaxProvider showBreathingCircleIllustration={false} accentColor={theme.color.accent100}>
        <ActivityDiaryProvider mainColor={theme.color.accent100}>
          <SleepDiaryProvider>
            <EatingLogProvider>
              <HopeKitProvider name="Values kit" mainPracticeTileColor={theme.color?.primary200}>
                {children}
              </HopeKitProvider>
            </EatingLogProvider>
          </SleepDiaryProvider>
        </ActivityDiaryProvider>
      </RelaxProvider>
    </BingeEatingMyPlanProvider>
  );
}
