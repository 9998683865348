import { useLazyQuery } from '@apollo/client';
import { useNavigation } from '@react-navigation/native';
import { Image } from 'react-native';
import { z } from 'zod';

import { AuthScreenContainer } from '@oui/app-core/src/components/AuthScreenContainer';
import { Button } from '@oui/app-core/src/components/Button';
import { View } from '@oui/app-core/src/components/View';
import { FormContainer, TextFormInput, useZodForm } from '@oui/app-core/src/form';
import { useI18n } from '@oui/app-core/src/lib/i18n';
import { graphql } from '@oui/lib/src/graphql/tada';

import { RootStackScreenProps } from '@src/types/navigation';

export const WelcomeQuery = graphql(`
  query WelcomeClaimCode($code: String!) {
    ouiUserEmailWithToken(value: $code)
  }
`);

export function Welcome(_props: {}) {
  const { $t } = useI18n();
  const navigation = useNavigation<RootStackScreenProps<'Welcome'>['navigation']>();
  const form = useZodForm(z.object({ code: z.string() }), { defaultValues: { code: '' } });
  const [query] = useLazyQuery(WelcomeQuery);

  return (
    <AuthScreenContainer
      heading={$t({ id: 'Welcome_title', defaultMessage: 'Hi there' })}
      graphic={
        <Image
          source={require('@src/assets/Welcome.png')}
          style={{
            position: 'absolute',
            width: '100%',
            height: undefined,
            bottom: 0,
            aspectRatio: 375 / 290,
          }}
        />
      }
    >
      <View style={{ paddingHorizontal: 20, marginTop: 15, gap: 25 }}>
        <FormContainer {...form}>
          <TextFormInput
            control={form.control}
            name="code"
            label="Enter claim code"
            placeholder="********"
          />
          <Button
            onPress={form.handleSubmit(async (data) => {
              try {
                const result = await query({ variables: { code: data.code } });
                if (result.data?.ouiUserEmailWithToken) {
                  const email = result.data?.ouiUserEmailWithToken;
                  navigation.navigate('SignUp', {
                    signupTokenFromActivationCode: data.code,
                    email: email.endsWith('@signup.oui.dev') ? undefined : email,
                  });
                } else {
                  form.setError('code', { message: 'Invalid code' });
                }
              } catch (e) {
                form.setError('code', { message: 'Invalid code' });
              }
            })}
            text="Next"
            alignSelf="center"
            variant="solid"
          />
        </FormContainer>
      </View>
      <Button
        style={{ marginTop: 60 }}
        onPress={() => navigation.navigate('Login', {})}
        text={$t({
          id: 'Welcome_navigateToLogin',
          defaultMessage: 'I already have an account',
        })}
        variant="text"
        alignSelf="center"
      />
    </AuthScreenContainer>
  );
}
