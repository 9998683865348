import { ComponentType, ReactNode } from 'react';
import { SafeAreaView, ScrollViewProps, StatusBar } from 'react-native';

import LoginFlower from '../assets/Login_flower.svg';
import LoginPlant from '../assets/Login_plant.svg';
import { useAppContext } from '../components/AppContext';
import { ScrollView } from '../components/ScrollView';
import { Heading } from '../components/Text';
import { View } from '../components/View';
import { manifest } from '../constants';
import { useWindowDimensions } from '../hooks/useWindowDimensions';
import { useI18n } from '../lib/i18n';
import { useTheme } from '../styles';

export function AuthScreenContainer(props: {
  testID?: string;
  heading: string | null;
  children: ReactNode;
  _scrollView?: ComponentType<ScrollViewProps>;
  graphic?: ReactNode;
  logo?: ReactNode;
}) {
  const { height } = useWindowDimensions();
  const { scheme, Color } = useTheme();
  const ScrollViewComponent = props._scrollView ?? ScrollView;
  const { Logo, onboardingGraphic } = useAppContext();
  const { $t } = useI18n();

  return (
    <View flex={1} style={{ backgroundColor: Color.backgroundColor }}>
      <SafeAreaView style={{ flex: 1, zIndex: 1 }}>
        <StatusBar barStyle={scheme === 'dark' ? 'light-content' : 'dark-content'} translucent />
        <ScrollViewComponent
          contentContainerStyle={{ paddingTop: height / 12, flexGrow: 1 }}
          testID={`${props.testID}_scrollView`}
        >
          {props.logo ? (
            props.logo
          ) : (
            <View style={{ alignItems: 'center' }}>
              <Logo
                height={height / 8}
                accessibilityLabel={$t(
                  {
                    id: 'AuthScreenContainer_logoLabel',
                    defaultMessage: '{appName} Logo',
                  },
                  { appName: manifest.name },
                )}
                accessibilityRole="image"
              />
            </View>
          )}
          <View
            style={{
              paddingHorizontal: 20,
              flex: 1,
              marginTop: 20,
            }}
            testID={props.testID}
          >
            {props.heading ? (
              <Heading
                text={props.heading}
                level={1}
                textAlign="center"
                style={{ marginTop: 20, marginBottom: 10 }}
              />
            ) : null}
            {props.children}
          </View>
        </ScrollViewComponent>
      </SafeAreaView>
      {props.graphic ?? onboardingGraphic ?? (
        <View
          style={{
            position: 'absolute',
            right: 0,
            left: 0,
            bottom: 0,
            flexDirection: 'row',
            alignSelf: 'stretch',
            alignItems: 'flex-end',
            justifyContent: 'space-between',
            paddingRight: 40,
          }}
        >
          <>
            <LoginPlant height={height / 6} accessibilityLabel={undefined} />
            <LoginFlower height={height / 6} accessibilityLabel={undefined} />
          </>
        </View>
      )}
    </View>
  );
}
