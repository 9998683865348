import { useIsFocused, useNavigation } from '@react-navigation/native';
import color from 'color';
import { ReactNode, useLayoutEffect } from 'react';
import { SafeAreaView, StatusBar, View } from 'react-native';

import { useAppContext } from '../components/AppContext';
import { ScrollView } from '../components/ScrollView';
import { Text } from '../components/Text';
import { useDefaultHeaderHeight } from '../lib/getDefaultHeaderHeight';
import { Color } from '../styles';

type Props = {
  children?: ReactNode;
  color: string;
  noScrollView?: boolean;
  innerSafeArea?: boolean;
  preview?: boolean;
  secondaryColor?: string;
  tintColor?: string;
  bottomSafeAreaColor?: string;
  overflowColor?: string;
  testID?: string;
  applyHeaderOptions?: true;
} & (
  | {
      subtitle: string;
      title: ReactNode;
    }
  | {
      subtitle?: string;
      title: string;
    }
);

export function RoundedSectionNavigationOptions({
  defaultHeaderHeight,
  headerTintColor,
  tintColor,
  backgroundColor,
}: {
  defaultHeaderHeight: number;
  headerTintColor?: string;
  tintColor?: string;
  backgroundColor: string;
}) {
  return {
    headerTintColor: tintColor,
    headerTitleStyle: { color: headerTintColor ?? tintColor, fontFamily: 'OpenSansSemiBold' },
    headerTitle: '',
    headerStyle: {
      backgroundColor: backgroundColor,
      borderBottomWidth: 0,
      elevation: 0,
      shadowOpacity: 0,
      height: defaultHeaderHeight,
    },
    // Keep this for backwards compatibility pre RoundedSection cleanup. However, we shouldn't rely on
    // headerRight being reset here and instead the caller should manually specify headerRight: undefined
    // if that's the desired behavior
    headerRight: undefined,
  };
}

export function RoundedSectionTopChild(props: { children?: ReactNode; backgroundColor: string }) {
  return (
    <View
      style={{
        backgroundColor: props.backgroundColor,
        marginTop: -20,
        marginHorizontal: -20,
        padding: 20,
        borderTopStartRadius: 30,
        borderTopEndRadius: 30,
        marginBottom: 20,
      }}
    >
      {props.children}
    </View>
  );
}

export function RoundedSection(props: Props) {
  const { setOptions } = useNavigation();
  const { navigationContainer } = useAppContext();
  const isDark = color(props.color).darken(0.1).isDark();
  const defaultHeaderHeight = useDefaultHeaderHeight();
  const isFocused = useIsFocused();

  useLayoutEffect(() => {
    if (props.noScrollView) {
      setOptions({ headerTitle: '' });
    }
    if (props.applyHeaderOptions) {
      setOptions(
        RoundedSectionNavigationOptions({
          backgroundColor: props.color,
          tintColor: props.tintColor || (isDark ? 'white' : Color.text),
          defaultHeaderHeight,
        }),
      );
    }
  }, [
    isDark,
    props.noScrollView,
    setOptions,
    props.applyHeaderOptions,
    props.color,
    props.secondaryColor,
    props.tintColor,
    defaultHeaderHeight,
  ]);

  const inner = (
    <>
      <View
        style={{
          backgroundColor: props.color,
          paddingTop: 10,
          paddingBottom: 20,
          paddingHorizontal: 10,
        }}
      >
        {props.subtitle && typeof props.title === 'string' ? (
          <Text
            text={props.title}
            accessibilityRole="header"
            weight="semibold"
            color={isDark ? 'white' : Color.text}
            size={15}
            style={{
              lineHeight: 22,
              textAlign: 'center',
              marginTop: -10,
              marginBottom: 10,
            }}
          />
        ) : null}
        {typeof props.title === 'string' ? (
          <Text
            text={props.subtitle || props.title}
            accessibilityRole="header"
            weight="bold"
            color={isDark ? 'white' : Color.text}
            size={28}
            style={{
              fontFamily: 'SourceSerifProSemiBold',
              lineHeight: 35,
              marginBottom: 30,
              textAlign: 'center',
            }}
          />
        ) : (
          props.title
        )}
      </View>

      <View
        style={{
          padding: 20,
          borderTopStartRadius: props.preview ? 0 : 30,
          borderTopEndRadius: props.preview ? 0 : 30,
          backgroundColor: props.secondaryColor || 'white',
          marginTop: -30,
          flexGrow: 1,
        }}
      >
        {props.children}
      </View>
    </>
  );

  const content = (
    <>
      {props.preview || !isFocused ? null : (
        <StatusBar
          translucent
          barStyle={isDark ? 'light-content' : 'dark-content'}
          backgroundColor={props.color}
        />
      )}
      {props.noScrollView ? (
        inner
      ) : (
        <ScrollView
          testID={props.testID}
          topOverflowColor={props.overflowColor ?? props.color}
          bottomOverflowColor={
            props.overflowColor ?? props.bottomSafeAreaColor ?? props.secondaryColor
          }
          contentContainerStyle={{ flexGrow: 1 }}
          scrollEventThrottle={8}
          onScroll={(e) => {
            const options = navigationContainer?.getCurrentOptions();
            const titleParam = (options as { headerTitle?: string })?.headerTitle;
            if (e.nativeEvent.contentOffset.y > 62 && !titleParam) {
              setOptions({ headerTitle: props.subtitle || props.title });
            } else if (e.nativeEvent.contentOffset.y < 62 && titleParam) {
              setOptions({ headerTitle: '' });
            }
          }}
        >
          {inner}
          {props.innerSafeArea ? <SafeAreaView /> : null}
        </ScrollView>
      )}
    </>
  );

  return props.innerSafeArea ? (
    content
  ) : (
    <>
      <SafeAreaView style={{ flex: 1 }} testID={props.noScrollView ? props.testID : undefined}>
        {content}
      </SafeAreaView>
      <SafeAreaView
        style={{ backgroundColor: props.bottomSafeAreaColor || props.secondaryColor }}
      />
    </>
  );
}
