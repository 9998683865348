import { NavigationContainerRefContext } from '@react-navigation/native';
// @ts-expect-error
import LinkingContext from '@react-navigation/native/lib/module/LinkingContext';
import { StackNavigationOptions } from '@react-navigation/stack';
import ColorLib from 'color';
import { ComponentProps, ReactNode, useContext, useRef } from 'react';
import { MenuContext } from 'react-native-popup-menu';

import {
  ContextMenu,
  Menu,
  MenuOption,
  MenuOptions,
  MenuTrigger,
  withMenuContext,
} from '../components/ContextMenu';
import { HeaderItem } from '../components/HeaderButtons';
import { Icon } from '../components/Icon';
import { LinkButton } from '../components/LinkButton';
import { Text } from '../components/Text';
import { View } from '../components/View';
import { useTheme } from '../styles';

type Option = {
  testID: string;
  icon: ComponentProps<typeof Icon>['name'];
  text: string;
  ctx: MenuContext;
} & (
  | {
      onPress: () => void;
      to?: never;
      params?: never;
    }
  | {
      onPress?: () => void;
      to: string;
      params?: Record<string, string | number | boolean | null | undefined>;
    }
);

function OverflowMenuOptionInner(props: Option) {
  const { theme } = useTheme();
  const inner = (
    <View row spacing={8} style={{ padding: 4, marginRight: 10 }} testID={props.testID}>
      <Icon name={props.icon} size={20} />
      <Text text={props.text} weight="semibold" size={17} />
    </View>
  );
  return (
    <MenuOption
      onSelect={() => {
        props.ctx.menuActions.closeMenu();
        requestAnimationFrame(() => props.onPress?.());
      }}
      key={props.testID}
      customStyles={{
        optionWrapper: { paddingHorizontal: 20, paddingVertical: 10, borderRadius: 10 },
        optionTouchable: {
          borderRadius: 10,
          overflow: 'hidden',
          underlayColor: ColorLib(theme.color.primary100).lighten(0.8).toString(),
        },
      }}
    >
      {props.to ? (
        <LinkButton
          to={props.to}
          params={props.params}
          onPress={() => props.ctx.menuActions.closeMenu()}
        >
          {inner}
        </LinkButton>
      ) : (
        inner
      )}
    </MenuOption>
  );
}

export const OverflowMenuOption = withMenuContext<Option>(OverflowMenuOptionInner);

export function OverflowMenu(props: {
  triggerColor?: string;
  triggerTestID: string;
  triggerAccessibilityLabel?: string;
  triggerIcon?: ComponentProps<typeof Icon>['name'];
  children?: ReactNode;
  headerItem?: boolean;
  TriggerTouchableComponent?: Function;
}) {
  const menuRef = useRef<Menu>(null);
  const navigation = useContext(NavigationContainerRefContext);
  const linking = useContext(LinkingContext);

  return (
    <Menu
      renderer={ContextMenu}
      rendererProps={{ customStyle: { borderRadius: 10, width: undefined, paddingVertical: 10 } }}
      ref={menuRef}
    >
      <MenuTrigger
        customStyles={{
          TriggerTouchableComponent: props.TriggerTouchableComponent,
          triggerTouchable: {
            accessibilityLabel: props.triggerAccessibilityLabel || 'More options',
          },
        }}
        testID={props.triggerTestID}
      >
        {props.headerItem ? (
          <HeaderItem
            title=""
            accessibilityLabel={undefined}
            iconName={props.triggerIcon ?? 'more'}
            onPress={() => {
              menuRef.current?.open();
            }}
            color={
              props.triggerColor ??
              (navigation?.getCurrentOptions() as StackNavigationOptions | undefined)
                ?.headerTintColor
            }
          />
        ) : (
          <Icon name={props.triggerIcon ?? 'more'} size={24} color={props.triggerColor} />
        )}
      </MenuTrigger>
      <MenuOptions
        customStyles={{
          optionsWrapper: { borderRadius: 4, overflow: 'hidden', backgroundColor: 'white' },
        }}
      >
        <NavigationContainerRefContext.Provider value={navigation}>
          <LinkingContext.Provider value={linking}>{props.children}</LinkingContext.Provider>
        </NavigationContainerRefContext.Provider>
      </MenuOptions>
    </Menu>
  );
}
