import { useQuery } from '@apollo/client';
import { useNavigation } from '@react-navigation/native';
import { StyleSheet } from 'react-native';

import { graphql } from '@oui/lib/src/graphql/tada';
import { encodeHostedQuizSetSlug } from '@oui/lib/src/quizSetSlug';
import type { ContentType } from '@oui/lib/src/types';
import { ProductVariant, ProductVersion } from '@oui/lib/src/types/graphql.generated';
import { GQLUUID } from '@oui/lib/src/types/scalars';

import { useCurrentPatient } from '../../../hooks/useCurrentUser';
import { useI18n } from '../../../lib/i18n';
import type { StackScreenProps } from '../../../types/navigation';
import { ActivityIndicator } from '../../ActivityIndicator';
import ChatArtifactPreview from '../../ChatArtifactPreview';
import { ErrorPresenter } from '../../ErrorPresenter';
import { Heading } from '../../Text';
import { View } from '../../View';

export type SessionVideosProps = {
  testID?: string;
  contentType: ContentType;
};

export const SessionVideosQuery = graphql(`
  query SessionVideos($contentType: String!, $productVariant: String!, $productVersion: String!) {
    sessionContentForApp(
      query: {
        contentType: $contentType
        productVariant: $productVariant
        productVersion: $productVersion
      }
    ) {
      __typename
      activities {
        name
        params
      }
      quizSetCollections {
        __typename
        quizSetCollectionID
        name
        sets {
          __typename
          ID
          description
          items {
            __typename
            quizSetItemID
            ... on QuizSetItemVideo {
              fileUpload {
                __typename
                fileName
                fileUploadID
                fileUploadType
                gcsPath
                mimeType
              }
              url
            }
          }
          legacyItems {
            __typename
            ... on LegacyQuizSetItemVideo {
              props {
                url
                title
                compulsory
              }
            }
          }
          name
          previewOptions {
            fitMode
            height
            time
          }
          previewTime
          quizSetID
        }
      }
      contentTypes
    }
  }
`);

export function SessionVideos({ testID, contentType }: SessionVideosProps) {
  const { $t } = useI18n();
  const { user } = useCurrentPatient();
  const { navigate } = useNavigation<StackScreenProps<'Conversation'>['navigation']>();

  const productVariant = user?.role.product.slug || ProductVariant.AVIVA_INPATIENT;
  const productVersion = user?.productVersion || ProductVersion.AVIVA_2_0;

  const { data, loading, error } = useQuery(SessionVideosQuery, {
    variables: {
      contentType,
      productVariant,
      productVersion,
    },
    fetchPolicy: 'cache-only',
  });

  const collections = data?.sessionContentForApp?.quizSetCollections;

  return (
    <View
      testID={testID}
      style={[
        {
          flex: 1,
          flexDirection: 'column',
          justifyContent: 'space-between',
        },
      ]}
      spacing={15}
    >
      <Heading
        level={2}
        text={$t({ id: 'Conversation_videoTabVideosHeadline', defaultMessage: 'Videos' })}
      />
      {loading ? (
        <View
          style={{
            flex: 1,
            marginVertical: 30,
            flexWrap: 'wrap',
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'space-around',
          }}
        >
          <ActivityIndicator size="large" style={StyleSheet.absoluteFillObject} />
        </View>
      ) : (
        <View
          style={[
            {
              flex: 1,
              flexWrap: 'wrap',
              flexDirection: 'row',
              justifyContent: 'space-between',
              margin: 5,
            },
          ]}
        >
          {error ? <ErrorPresenter error={error} /> : null}
          {collections?.map((collection, i) => (
            <View
              key={collection.quizSetCollectionID}
              style={{ marginBottom: i < collections.length - 2 ? 40 : 0 }}
            >
              <ChatArtifactPreview
                _width={159}
                _height={177}
                _onSideEffect={() => {
                  navigate('QuizSet', {
                    slug: encodeHostedQuizSetSlug({
                      quizSetCollectionID: collection.quizSetCollectionID as GQLUUID,
                    }),
                  });
                }}
                artifactName="QuizSet"
                params={{
                  slug: encodeHostedQuizSetSlug({
                    quizSetCollectionID: collection.quizSetCollectionID as GQLUUID,
                  }),
                }}
                linkText={collection.name}
              />
            </View>
          ))}
          {(collections?.length ?? 0) % 2 === 1 ? <View style={{ width: 159 }} /> : null}
        </View>
      )}
    </View>
  );
}
