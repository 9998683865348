import { ComponentProps, forwardRef } from 'react';
import { View } from 'react-native';
import { AccessibleInput } from 'src/hooks/useAccessibleInput';

import { Button } from '../Button';
import { NumberInput } from '../TextInput';

export type NumericStepperInputProps = {
  value?: number;
  onChangeValue: (value: number) => void;
  min?: number;
  max?: number;
  variant?: 'contained' | 'flat';
  testID?: string;
  label?: string;
  accessibilityLabel?: string;
} & Pick<ComponentProps<typeof AccessibleInput>, 'error' | 'required' | 'disabled'>;

export const NumericStepperInput = forwardRef<View, NumericStepperInputProps>(
  (
    {
      value: initialValue,
      onChangeValue,
      min = 0,
      max = 100,
      label,
      accessibilityLabel,
      testID,
      ...rest
    },
    ref,
  ) => {
    const value = initialValue ?? 0;

    const handleChange = (newValue: number | null) => {
      if (typeof newValue === 'number') {
        const clampedValue = Math.max(min, Math.min(max, newValue));
        onChangeValue(clampedValue);
      } else {
        onChangeValue(Math.max(min, 0));
      }
    };

    const decrement = () => handleChange(value - 1);
    const increment = () => handleChange(value + 1);

    return (
      <View
        style={{
          flexDirection: 'row',
          alignItems: 'center',
          justifyContent: 'center',
          gap: 20,
        }}
        ref={ref}
      >
        <Button
          onPress={decrement}
          accessibilityLabel="Decrease value"
          variant="text"
          text=""
          icon="minus"
        />

        <NumberInput
          style={{ width: 100 }}
          inputStyle={{ textAlign: 'center' }}
          label={label}
          accessibilityLabel={accessibilityLabel}
          value={value}
          onChangeValue={(val) => handleChange(val)}
          testID={testID}
          {...rest}
        />

        <Button
          onPress={increment}
          accessibilityLabel="Increase value"
          variant="text"
          text=""
          icon="plus"
        />
      </View>
    );
  },
);
