import { ApolloClient } from '@apollo/client';
import Constants from 'expo-constants';
import * as Notifications from 'expo-notifications';
import { Platform } from 'react-native';

import { graphql } from '@oui/lib/src/graphql/tada';

import { getInstallationIDAsync } from '../lib/getInstallationIDAsync';
import Sentry from '../sentry';

export const SetPushTokenMutation = graphql(`
  mutation SetPushToken(
    $deviceID: UUID!
    $platform: String!
    $platformPushToken: String
    $expoPushToken: String
  ) {
    setDevice(
      device: {
        installationID: $deviceID
        platform: $platform
        pushToken: $platformPushToken
        expoToken: $expoPushToken
      }
    )
  }
`);

export async function setDeviceInfo(client: ApolloClient<unknown>) {
  const { status } =
    Platform.OS === 'web' || !Constants.isDevice
      ? { status: 'denied' } // currently don't support notifications on web
      : await Notifications.getPermissionsAsync();
  const installationID = await getInstallationIDAsync();

  let expoPushToken: Notifications.ExpoPushToken | undefined;
  let platformPushToken: string | undefined;
  if (status === 'granted') {
    expoPushToken = await Notifications.getExpoPushTokenAsync({
      projectId: Constants.expoConfig?.extra?.eas.projectId,
    });
    try {
      platformPushToken = (await Notifications.getDevicePushTokenAsync()).data;
    } catch (e) {}
  }

  client
    .mutate({
      mutation: SetPushTokenMutation,
      variables: {
        deviceID: installationID,
        platform: Platform.OS,
        expoPushToken: expoPushToken?.data,
        platformPushToken,
      },
    })
    .catch((e) => Sentry.captureException(e));
}
