import { createElement, ElementType, useMemo } from 'react';
import { TouchableOpacity } from 'react-native';

import { ChatArtifactPreviewProps } from '@oui/lib/src/types';

import { Button } from '../components/Button';
import { View } from '../components/View';
import { APP_SLUG } from '../constants';
import { useChatSideEffect } from '../hooks/useChatSideEffect';
import { QuizSetPreview } from '../screens/QuizSet';
import { CHAT_PREVIEW_BORDER_RADIUS } from '../styles';
import { CoreRootStackParamList as RootStackParamList } from '../types/navigation';

const HEIGHT = 125;
const WIDTH = 210;
const PADDING = 0;

const ROUTE_NAME_LOOKUP: { [key: string]: keyof RootStackParamList | undefined } = {};

const PREVIEW_COMPONENTS: {
  [key: string]:
    | null
    | ((dimensions: { width: number; height: number; defaultHeight?: number }) => ElementType);
} = {
  QuizSet:
    ({ width, height }) =>
    ({ slug }: { slug: string }) => <QuizSetPreview width={width} height={height} slug={slug} />,
};

export function registerChatArtifactPreviewComponent(
  key: string,
  elementFn: (typeof PREVIEW_COMPONENTS)[string],
) {
  PREVIEW_COMPONENTS[key] = elementFn;
}

export function ChatArtifactPreview({
  artifactName,
  linkText,
  testID,
  params,
  _height,
  _width,
  _onSideEffect,
}: ChatArtifactPreviewProps & {
  _width?: number;
  _height?: number;
  _onSideEffect?: (effect: {
    kind: 'navigate';
    routeName: string;
    params: { [key: string]: string | boolean };
  }) => void;
}) {
  const width = _width ?? WIDTH;
  const height = _height ?? HEIGHT;

  const onSideEffect = useChatSideEffect();

  const routeName = ROUTE_NAME_LOOKUP[artifactName] ?? artifactName;
  let previewComponent = useMemo(() => {
    return PREVIEW_COMPONENTS[routeName]?.({ width, height, defaultHeight: HEIGHT }) ?? null;
  }, [routeName, width, height]);

  if (APP_SLUG === 'oui-aviva-staff') {
    /*
    We cannot render a component preview from within the CMS because we have a dependency 
    on a Modal wrapper. This wrapper is only provided in the app.
  */
    previewComponent = null;
  }

  const shouldTransformPreview = routeName !== 'QuizSet';

  const onPress = () =>
    (_onSideEffect ?? onSideEffect)({
      kind: 'navigate',
      routeName,
      params: { ...params },
    });

  return (
    <TouchableOpacity onPress={onPress}>
      <View spacing={10}>
        <View
          style={{
            width,
            height,
            borderRadius: CHAT_PREVIEW_BORDER_RADIUS,
            borderWidth: artifactName === 'HopeKit' ? undefined : 1,
            padding: PADDING,
          }}
          accessibilityElementsHidden
          importantForAccessibility="no-hide-descendants"
        >
          <View
            style={[
              {
                flexGrow: 1,
                overflow: 'hidden',
              },
              shouldTransformPreview
                ? {
                    borderRadius: CHAT_PREVIEW_BORDER_RADIUS * 2,
                    height: height * 2 - PADDING * 2 - 2,
                    transform: [{ scale: 0.5 }, { translateY: -height }, { translateX: -width }],
                    width: width * 2 - PADDING * 2 - 2,
                  }
                : {
                    borderRadius: CHAT_PREVIEW_BORDER_RADIUS,
                    height: height - PADDING - 2,
                    width: width - PADDING - 2,
                  },
            ]}
            pointerEvents="none"
          >
            {previewComponent
              ? createElement(previewComponent, { preview: true, ...params })
              : null}
          </View>
        </View>
        <Button
          testID={testID}
          onPress={onPress}
          text={linkText}
          variant="text"
          alignSelf="center"
          style={{ maxWidth: WIDTH }}
        />
      </View>
    </TouchableOpacity>
  );
}

export default ChatArtifactPreview;
