import * as Sentry from '@sentry/react-native';
import { init } from '@sentry/react-native';
import Constants from 'expo-constants';
import noop from 'lodash/noop';
import { Platform } from 'react-native';
import Url from 'url-parse';

import { Environment, environment, SENTRY_DSN } from './constants';
import { _configuration } from './lib/NetInfo';
import SentryRRWeb from './rrweb';

export const routingInstrumentation = new Sentry.ReactNavigationInstrumentation();
let sentry = Sentry;

const isWeb = Platform.OS === 'web';

function sanitizeUrl(source: string): string {
  const url = new Url(source, {}, { parser: true });
  Object.keys(url.query).forEach((key) => {
    if (['token', 'authToken', 'password', 'resetToken'].includes(key)) {
      url.query[key] = '[redacted]';
    }
  });
  return url.toString();
}

if (process.env.NODE_ENV !== 'test') {
  init({
    enabled: process.env.NODE_ENV !== 'development',
    beforeSend: (evt, b) => {
      if (evt.request?.url) {
        evt.request.url = sanitizeUrl(evt.request.url);
      }
      if (evt.request?.headers?.['Referer']) {
        evt.request.headers['Referer'] = sanitizeUrl(evt.request.headers['Referer']);
      }
      return evt;
    },
    environment: environment,
    dsn: SENTRY_DSN,
    maxBreadcrumbs: 100,
    enableAutoSessionTracking: true,
    enableAppHangTracking: false, // This is really noisy for us, if enabled
    beforeBreadcrumb(breadcrumb, hint) {
      const is204 =
        breadcrumb.category === 'xhr' &&
        breadcrumb.data?.url === _configuration.reachabilityUrl &&
        breadcrumb.data?.status_code === 204;
      const isMuxData =
        breadcrumb.category === 'xhr' &&
        breadcrumb.data?.url === 'https://ube37gkhuapjipih1qa0ul5e2.litix.io';
      return is204 || isMuxData ? null : breadcrumb;
    },
    tracesSampleRate: 0.05,
    enableAutoPerformanceTracing: false, // if enabled, breaks detox synchronization
    integrations: [
      // enabling traces to a non-1 value causes the idleTimeout option of ReactNativeTracing to
      // endlessly trigger a timeout which causes detox's synchronization to never consider the
      // device to be settled. To compensate, we don't enable the tracing integration on simulators
      Constants.isDevice
        ? new Sentry.ReactNativeTracing({
            enableAppStartTracking: !isWeb,
            enableStallTracking: !isWeb,
            enableNativeFramesTracking: !isWeb,
            tracingOrigins: ['localhost', 'oui.dev', 'oui.health', /^\//],
            routingInstrumentation,
          })
        : undefined!,
      SentryRRWeb && environment !== Environment.PRODUCTION
        ? new SentryRRWeb({
            maskAllInputs: false,
            collectFonts: true,
          })
        : undefined!,
    ].filter((i) => !!i),
  });
} else {
  const IS_TEST = process.env.NODE_ENV === 'test';
  const log = IS_TEST ? noop : console.log;
  sentry = {
    addBreadcrumb: noop,
    captureException: IS_TEST ? noop : console.warn,
    captureMessage: log,
    setUser: noop,
    nativeCrash: () => console.warn('Enable Sentry in development to enable nativeCrash'),
    withScope: (cb: Function) => cb({ setExtra: log, setExtras: log, setTag: log }),
    getCurrentHub: Sentry.getCurrentHub,
  } as unknown as typeof Sentry;
}

export default sentry;
