import { useRef, useState } from 'react';
import { Platform } from 'react-native';
import { v4 as uuid } from 'uuid';

import { Text } from '../components/Text';
import { TextInput } from '../components/TextInput';
import { View } from '../components/View';

const LINE_HEIGHT = 20;
const BULLET_SIZE = 8;

type Item = { ID: string; text: string };

export function ListItemTextInput<T extends Item>(
  props: {
    error?: string;
    autoFocus?: boolean;
    bulletColor?: string;
    minHeight?: number;
    value: ReadonlyArray<T>;
    onChangeValue: (items: T[]) => void;
    testID?: string;
    placeholder?: string;
  } & (
    | { label?: never; accessibilityLabel: string | undefined }
    | { label: string; accessibilityLabel?: string }
  ),
) {
  const selectionRef = useRef({ start: 0, end: 0 });
  const items = props.value;
  const value = items.map((i) => i.text).join('\n');
  const [labelHeight, setLabelHeight] = useState(props.label ? 23 : 0);

  return (
    <View>
      <TextInput
        onLabelLayout={(e) => {
          setLabelHeight(e.nativeEvent.layout.height);
        }}
        accessibilityLabel={props.accessibilityLabel}
        placeholder={props.placeholder ?? props.accessibilityLabel}
        testID={props.testID}
        label={props.label ?? undefined}
        error={props.error}
        style={{ zIndex: 1 }}
        autoFocus={props.autoFocus}
        multiline
        initialContentHeight={props.minHeight}
        textAlignVertical="top"
        onSelectionChange={(e) => {
          selectionRef.current = e.nativeEvent.selection;
        }}
        onKeyPress={(e) => {
          if (e.nativeEvent.key === 'Backspace') {
            if (
              selectionRef.current.start === 0 &&
              selectionRef.current.end === 0 &&
              items[0]?.text === ''
            ) {
              props.onChangeValue(items.slice(1));
            } else if (
              selectionRef.current.start === 0 &&
              selectionRef.current.end === value.length
            ) {
              props.onChangeValue([]);
            } else if (
              selectionRef.current.start === 1 &&
              selectionRef.current.end === 1 &&
              items.length === 1 &&
              items[0].text.length === 1
            ) {
              props.onChangeValue([]);
            }
          }
        }}
        onChangeValue={(text) => {
          if (text === '') {
            // onSelectionChange behaves unexpectely on android. If the entire input is selected and
            // backspace is pressed, the selection first jumps to the every end of the text before
            // onKeyPress is called. However, onChangeValue is still called with "" so that gives
            // us an opportunity to properly respond
            if (
              Platform.OS === 'android' &&
              selectionRef.current.start == selectionRef.current.end
            ) {
              props.onChangeValue([]);
            }
            return;
          }
          const result = text
            .split('\n')
            .map((t, i) => ({ ...items[i], ID: items[i]?.ID || uuid(), text: t }));
          props.onChangeValue(result);
        }}
        value={value}
        inputStyle={{
          lineHeight: LINE_HEIGHT,
          marginVertical: 0,
          paddingStart: 40,
          paddingTop: 12,
        }}
      />
      <View
        pointerEvents="none"
        style={{
          bottom: 0,
          left: 40 - 16 - BULLET_SIZE / 2,
          marginVertical: 0,
          position: 'absolute',
          right: 16,
          top: (props.label ? labelHeight + 5 : 0) + 12 + 1,
          zIndex: 2,
        }}
        accessibilityElementsHidden
        importantForAccessibility="no-hide-descendants"
      >
        {(items.length ? items : ([{ ID: '', text: '' }] as ReadonlyArray<Item>)).map(
          ({ text }, i) => (
            <View
              row
              style={{ alignItems: 'flex-start', minHeight: LINE_HEIGHT }}
              key={i}
              spacing={12}
            >
              <View
                style={{
                  marginTop: LINE_HEIGHT / 2 - BULLET_SIZE / 2,
                  width: BULLET_SIZE,
                  height: BULLET_SIZE,
                  backgroundColor: props.bulletColor ?? 'black',
                  borderRadius: BULLET_SIZE / 2,
                }}
              />
              <View flex={1}>
                <Text
                  accessibilityRole="none"
                  text={text}
                  color="red"
                  style={{
                    lineHeight: LINE_HEIGHT,
                    opacity: 0,
                  }}
                  size={14}
                />
              </View>
            </View>
          ),
        )}
      </View>
    </View>
  );
}
