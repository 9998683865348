import RNDateTimePicker from '@react-native-community/datetimepicker';
import parseISO from 'date-fns/parseISO';
import noop from 'lodash/noop';
import { ReactNode, useState } from 'react';
import type { FormatDateOptions } from 'react-intl';
import { Platform, StyleProp, StyleSheet, TouchableWithoutFeedback, ViewStyle } from 'react-native';
import DateTimePicker, { DateTimePickerProps } from 'react-native-modal-datetime-picker';
import { Menu, MenuOptions, MenuTrigger, renderers } from 'react-native-popup-menu';

import { getGQLDate } from '@oui/lib/src/getGQLDate';
import { parseHoursAndMinutes } from '@oui/lib/src/parseHoursAndMinutes';
import {
  GQLDate,
  GQLDateOnly,
  GQLDateTime,
  GQLTime,
  GQLTimeOfDay,
} from '@oui/lib/src/types/scalars';

import { useAppContext } from '../components/AppContext';
import { Icon } from '../components/Icon';
import { Text } from '../components/Text';
import { TextInput } from '../components/TextInput';
import { View } from '../components/View';
import { WebDatePickerInner } from '../components/WebDatePickerInner';
import { IOS_14_OR_GREATER } from '../constants';
import { AccessibleInput } from '../hooks/useAccessibleInput';
import { useI18n } from '../lib/i18n';
import { Color } from '../styles';

export const IOSDateTimeInputHeader = ({ mode }: { mode: Props['mode'] }) => {
  const { $t } = useI18n();
  const label =
    mode === 'time'
      ? $t({ id: 'DateTimeInput_timeHeading', defaultMessage: 'Pick a time' })
      : $t({ id: 'DateTimeInput_dateHeading', defaultMessage: 'Pick a date' });
  return (
    <View
      style={{
        borderBottomColor: '#d5d5d5',
        borderBottomWidth: StyleSheet.hairlineWidth,
        padding: 14,
        backgroundColor: 'transparent',
      }}
    >
      <Text size={18} weight="semibold" color={'#8f8f8f'} textAlign="center" text={label} />
    </View>
  );
};

type Props<T = GQLTime | GQLDate | GQLTimeOfDay | GQLDateOnly | GQLDateTime> = Pick<
  DateTimePickerProps,
  'minimumDate' | 'maximumDate'
> & {
  disabled?: boolean;
  error?: string;
  required?: boolean;
  minuteInterval?: 1 | 2 | 5 | 10 | 15 | 20 | 30;
  mode?: 'date' | 'datetime' | 'time';
  onChangeValue?: (value: T) => void;
  placeholder?: string;
  style?: StyleProp<ViewStyle>;
  testID?: string;
  formatOptions?: FormatDateOptions;
  value?: T | null;
  variant?: 'contained' | 'flat';
} & (
    | { label?: never; accessibilityLabel: string | undefined }
    | { label: string | (() => ReactNode); accessibilityLabel?: string }
  );

export type DateTimeInputProps<
  T extends GQLTime | GQLDate | GQLTimeOfDay | GQLDateOnly | GQLDateTime,
> = Props<T>;

export function DateTimeInput<
  T extends GQLTime | GQLDate | GQLTimeOfDay | GQLDateOnly | GQLDateTime,
>({
  accessibilityLabel,
  disabled,
  error,
  label,
  mode = 'datetime',
  minuteInterval = 15,
  onChangeValue,
  placeholder,
  style,
  value,
  variant,
  testID,
  ...props
}: Props<T>) {
  const [isVisible, setIsVisible] = useState(false);
  const { flags } = useAppContext();
  const { formatDate, formatTime, $t } = useI18n();

  const date = value
    ? mode === 'time'
      ? parseHoursAndMinutes(value)
      : parseISO(value)
    : undefined;

  function onDateChange(newDate: Date) {
    // This should be called before onChangeValue to prevent the DateTimePicker from re-appearing
    // on android
    // https://github.com/ouihealth/oui/issues/733
    setIsVisible(false);
    if (onChangeValue) {
      if (mode === 'time') {
        const hoursMinutes = formatDate(newDate, {
          hour: 'numeric',
          minute: '2-digit',
          hourCycle: 'h23',
        });
        onChangeValue(hoursMinutes as T);
      } else {
        onChangeValue((mode === 'datetime' ? newDate.toISOString() : getGQLDate(newDate)) as T);
      }
    }
  }

  const dateValue = date
    ? mode === 'datetime'
      ? formatDate(date, {
          year: 'numeric',
          month: 'long',
          day: 'numeric',
          hour: 'numeric',
          minute: 'numeric',
          ...props.formatOptions,
        })
      : mode === 'date'
        ? formatDate(date, {
            year: 'numeric',
            month: 'long',
            day: 'numeric',
            ...props.formatOptions,
          })
        : formatTime(date, {
            hour: 'numeric',
            minute: 'numeric',
            ...props.formatOptions,
          })
    : '';

  if (global.e2e && Platform.OS === 'android') {
    return (
      <View style={style}>
        <TextInput
          testID={testID}
          label={label}
          onChangeValue={(v) => {
            onDateChange(parseISO(v));
          }}
          value={date?.toISOString() ?? ''}
        />
      </View>
    );
  }

  return (
    <AccessibleInput
      placeholder={placeholder}
      error={error}
      accessibilityLabel={accessibilityLabel}
      label={label as string}
      style={style}
      testID={testID}
    >
      {(accessibleProps) => (
        <Menu
          opened={isVisible}
          onBackdropPress={() => setIsVisible(false)}
          renderer={renderers.ContextMenu}
        >
          <MenuTrigger>
            <TouchableWithoutFeedback
              onPress={() => setIsVisible(true)}
              testID={testID}
              {...accessibleProps}
              accessibilityLabel={
                (dateValue ? `${dateValue}.` : '') +
                accessibleProps.accessibilityLabel +
                ` ${mode} picker`
              }
              onAccessibilityAction={(event) => {
                switch (event.nativeEvent.actionName) {
                  case 'activate':
                    setIsVisible(true);
                    break;
                }
              }}
            >
              <View>
                <View
                  pointerEvents="box-only"
                  importantForAccessibility="no-hide-descendants"
                  accessibilityElementsHidden
                >
                  <TextInput
                    onChangeValue={noop}
                    placeholder={placeholder}
                    variant={variant}
                    value={dateValue}
                    editable={false}
                  />
                  {mode === 'date' ? (
                    <Icon
                      name="calendar"
                      style={{
                        position: 'absolute',
                        top: '50%',
                        right: 20,
                        transform: [{ translateY: -10 }],
                      }}
                      color={Color.styleGuide.Gray5}
                    />
                  ) : mode === 'time' ? (
                    <Icon
                      name="clock"
                      style={{
                        position: 'absolute',
                        top: '50%',
                        right: 20,
                        transform: [{ translateY: -10 }],
                      }}
                      color={Color.styleGuide.Gray5}
                    />
                  ) : null}
                </View>
                {flags.useIOS14DatePicker && IOS_14_OR_GREATER ? (
                  <RNDateTimePicker
                    accessible={false}
                    value={date ?? new Date()}
                    style={[
                      StyleSheet.absoluteFillObject,
                      { height: 56, opacity: 0.02, zIndex: 1 },
                    ]}
                    mode={mode}
                    display="compact"
                    onChange={(_, newDate) => newDate && onDateChange(newDate)}
                  />
                ) : null}
              </View>
            </TouchableWithoutFeedback>
          </MenuTrigger>
          {/* Menu requires MenuOptions as a child otherwise it warns */}
          {Platform.OS !== 'web' ? <MenuOptions></MenuOptions> : null}
          {Platform.OS !== 'web' ? (
            <DateTimePicker
              {...props}
              display={IOS_14_OR_GREATER ? 'spinner' : undefined}
              customHeaderIOS={() => {
                return <IOSDateTimeInputHeader mode={mode} />;
              }}
              date={date}
              mode={mode}
              isVisible={isVisible}
              onConfirm={onDateChange}
              onCancel={() => {
                setIsVisible(false);
              }}
              minuteInterval={minuteInterval}
              testID="DateTimePicker"
              cancelTextIOS={$t({ id: 'DateTimeInput_cancelButton', defaultMessage: 'Cancel' })}
              confirmTextIOS={$t({ id: 'DateTimeInput_confirmButton', defaultMessage: 'Confirm' })}
            />
          ) : (
            <MenuOptions>
              <WebDatePickerInner
                mode={mode}
                date={date || new Date()}
                onDateChange={onDateChange}
                onCancel={() => setIsVisible(false)}
                minuteInterval={minuteInterval}
                minimumDate={props.minimumDate}
                maximumDate={props.maximumDate}
              />
            </MenuOptions>
          )}
        </Menu>
      )}
    </AccessibleInput>
  );
}
