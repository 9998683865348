'use client';

import { ComponentProps } from 'react';
import { Platform, ActivityIndicator as RNActivityIndicator } from 'react-native';

import { useTheme } from '../styles';

export function ActivityIndicator(props: ComponentProps<typeof RNActivityIndicator>) {
  const { Color } = useTheme();
  return (
    <RNActivityIndicator
      color={Platform.OS === 'android' ? Color.accent : undefined}
      {...props}
      accessibilityLabel="Loading"
      accessibilityState={{ busy: true }}
    />
  );
}
