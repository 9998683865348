import { useQuery } from '@apollo/client';
import { useNavigation } from '@react-navigation/native';
import { Image } from 'react-native';

import { AuthScreenContainer } from '@oui/app-core/src/components/AuthScreenContainer';
import { Button } from '@oui/app-core/src/components/Button';
import { LottieView } from '@oui/app-core/src/components/LottieView';
import { Heading, Label } from '@oui/app-core/src/components/Text';
import { View } from '@oui/app-core/src/components/View';
import { useI18n } from '@oui/app-core/src/lib/i18n';
import { graphql } from '@oui/lib/src/graphql/tada';

import { RootStackScreenProps } from '@src/types/navigation';

export const GetStartedQuery = graphql(`
  query GetStarted {
    user {
      ID
      givenName
    }
  }
`);

export function GetStarted(_props: {}) {
  const { $t } = useI18n();
  const navigation = useNavigation<RootStackScreenProps<'GetStarted'>['navigation']>();
  const { data } = useQuery(GetStartedQuery, { fetchPolicy: 'cache-first' });

  const onSubmit = () => {
    navigation.navigate('home', {
      screen: 'Home',
    });
  };

  return (
    <AuthScreenContainer
      testID="GetStarted"
      heading={null}
      logo={<LottieView source={require('@src/assets/GetStarted_animation.json')} autoPlay loop />}
      graphic={
        <Image
          source={require('@src/assets/GetStarted_background.png')}
          style={{
            position: 'absolute',
            width: '100%',
            height: undefined,
            bottom: 0,
            aspectRatio: 375 / 161,
          }}
        />
      }
    >
      <Heading
        text={$t(
          { id: 'GetStarted_title', defaultMessage: 'Welcome, {name}' },
          { name: data?.user?.givenName ?? '' },
        )}
        level={1}
        textAlign="center"
      />
      <View
        style={{
          gap: 16,
          marginTop: 20,
          marginBottom: 50,
          paddingHorizontal: 20,
        }}
      >
        <Label
          textAlign="center"
          text={$t({
            id: 'GetStarted_description',
            defaultMessage:
              "We're glad you're here.\n\nThis app aims to help stop your bingeing, and put you on a healthy path towards losing weight.\n\nCongratulations on taking the first step to a healthier you. ",
          })}
        />
      </View>
      <Button
        alignSelf="center"
        onPress={onSubmit}
        text={$t({ id: 'GetStarted_submitButton', defaultMessage: 'Get started' })}
        variant="solid"
      />
    </AuthScreenContainer>
  );
}
