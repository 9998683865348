import hexToRgba from 'hex-to-rgba';
import { StyleSheet, View } from 'react-native';

import { Color } from '../styles';

export function SegmentedProgressBar({
  length,
  progress,
  variant,
  height = 20,
}: {
  length: number;
  progress: number;
  variant?: 'dark';
  height?: number;
}) {
  return (
    <View style={{ padding: 4, flexDirection: 'row', height, flex: 1 }}>
      {Array.from({ length: length }).map((_, i) => {
        const isFirst = i === 0;
        const isLast = i === length - 1;
        const isPending = i > progress - 1;

        return (
          <View
            key={i}
            style={[
              {
                height: '100%',
                flex: 1,
                backgroundColor:
                  variant === 'dark'
                    ? isPending
                      ? hexToRgba('#FFFFFF', 0.3)
                      : 'white'
                    : isPending
                      ? hexToRgba(Color.primary, 0.3)
                      : Color.primary,
              },
              isFirst ? styles.first : isLast ? styles.last : styles.middle,
            ]}
          />
        );
      })}
    </View>
  );
}

const styles = StyleSheet.create({
  first: {
    borderTopStartRadius: 20,
    borderBottomStartRadius: 20,
  },
  last: {
    borderBottomEndRadius: 20,
    borderTopEndRadius: 20,
    marginStart: 2,
  },
  middle: {
    marginStart: 2,
  },
});
