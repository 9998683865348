'use client';

import * as Sentry from '@sentry/core';
import { FunctionComponent } from 'react';
import { Platform, StyleProp, TouchableOpacity, View, ViewStyle } from 'react-native';
import { TouchableOpacity as GHTouchableOpacity } from 'react-native-gesture-handler';
import { SvgProps } from 'react-native-svg';

import Icons from '../assets/icons';
import { solveColor } from '../lib/colorFilter';
import { addBreadcrumb } from '../lib/log';
import { isInteractionPaused } from '../lib/pauseInteraction';
import { useTheme } from '../styles';

const CUSTOM_ICONS = {
  ...Icons,
};

export function registerIcons(
  icons: Record<
    string,
    FunctionComponent<SvgProps & { accessibilityLabel: string | undefined }> | undefined
  >,
) {
  Object.assign(CUSTOM_ICONS, icons);
}

type CustomIconsType = typeof CUSTOM_ICONS;
export function getIconNames(): (keyof AppCore.IconGlyphs)[] {
  return Object.keys(CUSTOM_ICONS) as (keyof AppCore.IconGlyphs)[];
}

declare global {
  // eslint-disable-next-line
  namespace AppCore {
    // eslint-disable-next-line
    interface IconGlyphs extends CustomIconsType {}
  }
}

type BaseIconProps = {
  _useGestureHandler?: boolean;
  _DANGER_NO_WRAPPER?: number;
  color?: string;
  dataSet?: object;
  disabled?: boolean;
  name: keyof AppCore.IconGlyphs | { src: string };
  size?: number;
  style?: StyleProp<ViewStyle>;
  testID?: string;
};

type IconPressProps =
  | {
      accessibilityLabel: string | undefined;
      onPress: () => void;
    }
  | {
      accessibilityLabel?: string | undefined;
      onPress?: never;
    };

export function Icon({
  _useGestureHandler,
  _DANGER_NO_WRAPPER,
  accessibilityLabel,
  color,
  dataSet,
  disabled,
  name = 'check',
  onPress: _onPress,
  size = 20,
  style,
  testID,
}: BaseIconProps & IconPressProps) {
  const { Color } = useTheme();
  color = color ?? Color.text;

  const onPress = _onPress
    ? () => {
        if (disabled) return;
        if (isInteractionPaused()) return;
        addBreadcrumb({
          category: 'ui.click',
          message: testID ?? accessibilityLabel ?? (typeof name === 'string' ? name : name.src),
          data: {
            componentType: 'Icon',
            testID,
            name,
            accessibilityLabel,
          },
        });
        _onPress?.();
      }
    : _onPress;
  const CustomIcon =
    // {name: src}
    typeof name === 'object' ||
    // require('./path.svg').default => any
    typeof name === 'function'
      ? name
      : (CUSTOM_ICONS[name as keyof typeof CUSTOM_ICONS] as
          | FunctionComponent<SvgProps & { accessibilityLabel: string | undefined }>
          | { src: string }
          | null);

  if (!CustomIcon) {
    Sentry.captureException('Missing icon', { extra: { name } });
    return null;
  }

  // if onPress is defined we set accessibilityLabel in the TouchableOpacity below
  const a11yProps =
    Platform.OS === 'web'
      ? {
          ['aria-label' as unknown as 'accessibilityLabel']: onPress
            ? undefined
            : accessibilityLabel,
        }
      : { accessibilityLabel: onPress ? undefined : accessibilityLabel };

  const inner =
    CustomIcon && typeof CustomIcon === 'object' && 'src' in CustomIcon ? (
      <img
        src={CustomIcon.src}
        width={_DANGER_NO_WRAPPER ?? '100%'}
        height={_DANGER_NO_WRAPPER ?? '100%'}
        style={color === 'inherit' ? undefined : { filter: solveColor(color) }}
      />
    ) : (
      <CustomIcon
        {...a11yProps}
        color={color}
        width={_DANGER_NO_WRAPPER ?? '100%'}
        height={_DANGER_NO_WRAPPER ?? '100%'}
        preserveAspectRatio="xMidYMid meet"
      />
    );

  if (_DANGER_NO_WRAPPER) return inner;
  const Component = _useGestureHandler ? GHTouchableOpacity : TouchableOpacity;
  return (
    <View
      testID={testID}
      style={[style, size ? { width: size, height: size } : null]}
      // @ts-expect-error
      dataSet={{ ...dataSet, icon: true }}
    >
      {onPress ? (
        <Component
          disabled={disabled}
          onPress={onPress}
          accessible
          accessibilityRole="button"
          accessibilityLabel={accessibilityLabel}
          accessibilityState={{
            disabled: disabled,
          }}
          hitSlop={{
            top: 10,
            right: 10,
            bottom: 10,
            left: 10,
          }}
          style={[{ height: '100%', width: '100%' }, disabled ? { opacity: 0.5 } : null]}
        >
          {inner}
        </Component>
      ) : (
        inner
      )}
    </View>
  );
}
